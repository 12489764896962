import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { User } from 'src/app/core/models/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent {
  @Input() profile: User;
  public isOpen = false;
  @Output() logout = new EventEmitter<boolean>();

  public toggle(isOpen: boolean): void {
    this.isOpen = isOpen;
  }

  public onLogout(): void {
    this.logout.emit(true);
  }
}
