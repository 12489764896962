/* eslint-disable @typescript-eslint/naming-convention */
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { UserContext } from '../models/user-context.model';
import { ErrorService } from '../services/error.service';
import { NotifierService } from '../services/notifier.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
    private notifierService: NotifierService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getTokenFromStorage();
    const sessionId = this.authService.getSessionIdFromLocalStorage();
    const context = this.authService.getContextFromLocalStorage();
    const contextId = context ? context.contextId : '';
    const userContext = new UserContext(sessionId, contextId);

    if (accessToken) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${accessToken}`,
          'User-Context': userContext.toString()
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.authService.redirectToLogin();
          } else if (error.status === 0 && error.statusText == 'Unknown Error') {
            this.errorService.setErrorMessage('Please contact uspstech@deloitte.com');
            this.router.navigateByUrl('/error');
          } else if (error.status === 403) {
            this.errorService.setErrorMessage(error.error.Message);
            this.router.navigateByUrl('/error');
          } else if (error.status === 500) {
            this.notifierService.notify(error.error.Message, true);
          }
        }
        return throwError(error);
      })
    );
  }
}
