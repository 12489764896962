import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ImportDto, ImportFieldDto } from 'src/app/core/models/import.model';
import { GenericGridProperties } from 'src/app/core/models/utils.models';
import { FieldService } from 'src/app/core/services/field.service';
import { ImportExportService } from 'src/app/core/services/import-export.service';
import { NotifierService } from 'src/app/core/services/notifier.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent {
  @Input() title: string = '';
  @Input() importData: ImportDto;
  @Input() fieldData: ImportFieldDto = new ImportFieldDto();
  @Input() isFieldData: boolean = false;
  @Output() closeClick = new EventEmitter<void>();

  public multipleFiles: boolean = false;
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx', 'xlsb']
  };
  public files: any[] = [];
  public renderValidationDialog: boolean = false;

  public importResponse: GenericGridProperties = {
    data: [],
    isValidationData: true,
    header: 'Import Validation Flags',
    fileName: ''
  };

  @ViewChild('fileSelect') fileSelect: any;
  constructor(
    private notifierService: NotifierService,
    private importExportService: ImportExportService,
    private fieldService: FieldService
  ) {}

  public onClose(): void {
    this.closeClick.emit();
  }

  public onFileSelect(e: SelectEvent) {
    this.files = e.files;
  }

  public onFileRemove(e: any) {
    this.files = [];
  }

  import() {
    if (this.files.length > 0) {
      if (this.files[0].extension === '.xlsx') {
        this.importExportService.importData(this.files[0], this.importData).subscribe((res) => {
          if (res != undefined) {
            if (res.Error == true && res.IsValidateException) {
              this.importResponse.data = res;
              this.importResponse.fileName = `${res.TableName} Import Errors.xlsx`;
              this.renderValidationDialog = true;
            } else {
              this.notifierService.notify(res.message, res.error);
              this.importExportService.refresh();
              this.onClose();
            }
          }
          this.files = [];
          this.fileSelect.clearFiles();
        });
      } else {
        this.notifierService.notify('Invalid file type. .xlsx file types are only allowed.', true);
      }
    } else {
      this.notifierService.notify('Please select a file to upload.', true);
    }
  }

  importFields() {
    if (this.files.length > 0) {
      if (this.files[0].extension === '.xlsx') {
        this.fieldService
          .importFields(this.fieldData.api, this.files[0], this.fieldData.formId, this.fieldData.profileId)
          .subscribe((res) => {
            if (res != undefined) {
              if (res.Error == true && res.IsValidateException) {
                this.importResponse.data = res;
                this.importResponse.fileName = `${res.TableName} Import Errors.xlsx`;
                this.renderValidationDialog = true;
              } else {
                this.notifierService.notify(res.message, res.error);
                this.importExportService.refresh();
                this.onClose();
              }
            }
            this.files = [];
            this.fileSelect.clearFiles();
          });
      } else {
        this.notifierService.notify('Invalid file type. .xlsx file types are only allowed.', true);
      }
    } else {
      this.notifierService.notify('Please select a file to upload.', true);
    }
  }
}
