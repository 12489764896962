<div class="p-4">
  <p>
    <strong>
      By using this website (this “Website”), you are agreeing to these Terms of Use on behalf of yourself and the legal
      entity for which you work as an employee, partner, director, principal or consultants (“your Company”). If you do
      not agree to these Terms of Use, then you are not allowed to use this Website and should immediately terminate
      such usage.
    </strong>
  </p>
  <p>
    The <strong>“Deloitte Network”</strong> refers to Deloitte Touche Tohmatsu Limited (<strong>“DTTL”</strong>), the
    member firms of DTTL, and their related entities.
  </p>
  <p>Use of Website; Restrictions; Privacy Statement</p>
  <p>
    On the condition that you comply with all of your obligations under these Terms of Use, you are authorized to use
    this Website solely for the purpose of managing your account and profile information in order to obtain access to
    one or more other applications and websites (“Other Applications”).
  </p>
  <p>
    Your use of such Other Applications are governed by all license terms, terms of use, legal terms, privacy notices,
    and all other terms on such Other Applications (“<strong>Other Application Terms</strong>”) and you and your Company
    must comply with all Other Application Terms.
  </p>
  <p>
    You and your Company are responsible for anything that happens through your user account until it is deactivated.
    Without limiting the foregoing, you and your Company agree to the following:
  </p>
  <ol>
    <li>
      You will only create and maintain one user account at any given time, which shall be for you alone and not shared
      or transferred to anyone else.
    </li>
    <li>
      You will only access the permitted sections of this Website that you have been granted access and will not hack
      into or otherwise attempt to access other areas of this Website.
    </li>
    <li>
      You and your Company will keep your user account password secure and confidential. You will not remove any
      copyright, trademark, or other proprietary rights notices found on this Website or its content.
    </li>
    <li>
      You will immediately cease all use of your user account and this Website upon the termination of your employment
      or other work relationship with your Company. Your Company must notify us in writing of such termination, and your
      Company remains responsible for your actions with respect to your user account and this Website until such
      notification and related deactivation of your user account.
    </li>
    <li>
      You and your Company will not engage in any action that directly or indirectly interferes with the proper working
      of, or places an unreasonable load on, his Website or the underlying infrastructure, including spamming and the
      distribution of computer viruses or other malicious code.
    </li>
    <li>
      You or your Company will notify us immediately upon becoming aware of any unauthorized use of your user account or
      any other breach of the above obligations.
    </li>
    <li>
      You are not authorized to copy or use any software, program, proprietary processes, tool, or technology embodied
      or described in this Website.
    </li>
  </ol>
  <p>You will comply with all applicable laws in accessing and using this Website.</p>
  <p>
    You acknowledge that your personal information and data may be used according to the Privacy Statement, which is
    incorporated herein by this reference. You and your Company hereby agree to the terms of the Privacy Statement,
    including any obligations imposed on you and your Company therein.
  </p>
  <h6>User Account Deactivation</h6>
  <p>
    You or your Company may deactivate your user account, for any or no reason, at any time, with written notice to us.
    This notice will be effective upon our processing such notice.
  </p>
  <p>
    Your user account may be deactivated for any reason or no reason, at any time, with or without notice, effective
    immediately or as may be specified in any given notice. Without limiting the foregoing, your user account may be
    deactivated by Website administration if inactive for extended time periods.
  </p>
  <p>
    Deactivation of your user account results in the immediate termination of your license to access and use this
    Website and ability to access and use Other Applications, and also the disabling, as soon as possible, of your
    technological access to this Website. In addition, you may be barred from any future use of this Website and also
    blocked from accessing this Website from any IP address or range of IP addresses associated with you.
  </p>
  <h6>Intellectual Property Rights; No use of Deloitte names or logos</h6>
  <p>
    This Website and its contents are protected by copyright, trademark, and other laws of the United States and/or
    foreign countries. All rights not expressly granted in these Terms of Use are reserved.
  </p>
  <p>
    “Deloitte”, “Touche”, “Tohmatsu”, “Deloitte Touche Tohmatsu”, “Deloitte & Touche”, the Deloitte logo, and local
    language variants of the foregoing trademarks, and certain product names that appear on this Website (collectively,
    the “Deloitte Marks”), are trademarks or registered trademarks of entities within the Deloitte Network. Except as
    expressly provided in these Terms of Use or as expressly authorized in writing by the relevant trademark owner,
    neither you nor your Company shall use any Deloitte Marks either alone or in combination with other words or design
    elements, including in any press release, advertisement, or other promotional or marketing material or media,
    whether in written, oral, electronic, visual or any other form.
  </p>
  <p>
    References to other parties’ trademarks on this Website are for identification purposes only and do not indicate
    that such parties have approved this Website or any of its contents. These Terms of Use do not grant you any right
    to use the trademarks of other parties.
  </p>
  <h6>Disclaimers and Limitations of Liability</h6>
  <p>ALL REFERENCES TO THIS WEBSITE INCLUDE, WITHOUT LIMITATION, ANY CONTENT OR OTHER PART THEREOF OF THIS WEBSITE.</p>
  <p>
    THIS WEBSITE CONTAINS GENERAL INFORMATION ONLY, AND NO ENTITY WITHIN THE DELOITTE NETWORK IS, BY MEANS OF THIS
    WEBSITE, RENDERING PROFESSIONAL ADVICE OR SERVICES. BEFORE MAKING ANY DECISION OR TAKING ANY ACTION THAT MIGHT
    AFFECT YOUR OR YOUR COMPANY’S FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR.
  </p>
  <p>
    THIS WEBSITE IS PROVIDED AS IS, AND NO ENTITY WITHIN THE DELOITTE NETWORK MAKES ANY EXPRESS OR IMPLIED
    REPRESENTATIONS OR WARRANTIES REGARDING IT. WITHOUT LIMITING THE FOREGOING, NO ENTITY WITHIN THE DELOITTE NETWORK
    WARRANTS THAT THIS WEBSITE WILL BE AVAILABLE, SECURE, ERROR-FREE, FREE FROM VIRUSES OR MALICIOUS CODE, OR WILL MEET
    ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY (INCLUDING WITH RESPECT TO THE RESULTS OBTAINED FROM THIS WEBSITE
    OR ITS CONTENT). ALL IMPLIED WARRANTIES ARE EXPRESSLY DISCLAIMED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
    MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY.
  </p>
  <p>
    YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK AND YOUR COMPANY’S OWN RISK AND YOU AND YOUR COMPANY ASSUME FULL
    RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE, INCLUDING, WITHOUT LIMITATION, WITH RESPECT TO LOSS OF
    SERVICE OR DATA. NO ENTITY WITHIN THE DELOITTE NETWORK WILL BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
    CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, WHETHER IN AN ACTION OF CONTRACT, STATUTE, TORT
    (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE, RELATING TO OR ARISING OUT OF THE USE OF THIS WEBSITE,
    EVEN IF THEY KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY OF SUCH DAMAGES.
  </p>
  <p>
    CERTAIN LINKS ON THIS WEBSITE MAY LEAD TO WEBSITES, RESOURCES, OR TOOLS (INCLUDING OTHER APPLICATIONS AS DEFINED IN
    THESE TERMS OF USE) MAINTAINED BY THIRD PARTIES, INCLUDING, WITHOUT LIMITATION, THOSE MAINTAINED BY OTHER ENTITIES
    WITHIN THE DELOITTE NETWORK OR INDIVIDUAL PERSONNEL OF SUCH ENTITIES. WITHOUT LIMITING ANY OF THE FOREGOING, NOTHING
    HEREIN, OR IN OR ABOUT THIS WEBSITE, SHALL BE CONSTRUED AS AN EXPRESS OR IMPLIED REPRESENTATION OR WARRANTY
    WHATSOEVER REGARDING SUCH WEBSITES, RESOURCES, AND TOOLS (INCLUDING OTHER APPLICATIONS AS DEFINED IN THESE TERMS OF
    USE), AND LINKS TO ANY SUCH WEBSITES, RESOURCES, AND TOOLS (INCLUDING OTHER APPLICATIONS AS DEFINED IN THESE TERMS
    OF USE) SHOULD NOT BE CONSTRUED AS AN ENDORSEMENT OF THEM OR THEIR CONTENT.
  </p>
  <p>
    THE ABOVE DISCLAIMERS AND LIMITATIONS OF LIABILITY SHALL BE APPLICABLE TO EACH ENTITY WITHIN THE DELOITTE NETWORK
    AND TO THEIR RESPECTIVE PERSONNEL.
  </p>
  <p>
    WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES SHALL ANY DELOITTE ENTITY BE LIABLE FOR THE ACTIONS OR
    INACTIONS OF ANY OTHER DELOITTE ENTITY, OR ANY PERSONNEL OF ANY OTHER DELOITTE ENTITY, WITH RESPECT TO THESE TERMS
    OF USE OR THIS WEBSITE.
  </p>
  <p>
    THE ABOVE DISCLAIMERS AND LIMITATIONS OF LIABILITY ARE APPLICABLE TO THE FULLEST EXTENT PERMITTED BY LAW, WHETHER IN
    CONTRACT, STATUTE, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE) OR OTHERWISE.
  </p>
  <h6>Additional Terms</h6>
  <p>
    If any portion of these Terms of Use is invalid or unenforceable in any jurisdiction, then (i) in that jurisdiction
    it shall be re-construed to the maximum effect permitted by law in order to effect its intent as nearly as possible,
    and the remainder of these Terms of Use shall remain in full force and effect, and (ii) in every other jurisdiction,
    all of these Terms of Use shall remain in full force and effect.
  </p>
  <p>
    These Terms of Use may be revised at any time by posting such revised Terms of Use at the Terms of Use link (i.e.,
    this webpage that you are currently viewing) or elsewhere in this Website. Such revisions shall be effective as to
    you and your Company upon posting, unless explicitly stated by us. It is your responsibility to be aware of any such
    revised Terms of Use by checking this webpage. Your continued use of this Website following changes to these Terms
    of Use constitutes your agreement and your Company’s to the revised Terms of Use.
  </p>
</div>
