<kendo-grid
  [kendoGridBinding]="gridData == undefined ? [] : gridData.data"
  [ngClass]="{ 'grid-sizing': isExport, 'page-grid-sizing-without-headers': !isExport }"
  [loading]="isGridLoading"
  [sortable]="true"
  filterable="menu"
  [pageable]="true"
  [resizable]="true"
  [pageSize]="kendoGridProperties.pageSize">
  <ng-template *ngIf="isExport" kendoGridToolbarTemplate>
    <div class="vertical-center grid-header">{{ this.title }}</div>
    <kendo-grid-spacer></kendo-grid-spacer>
    <button type="button" kendoGridExcelCommand class="btn btn-primary">Export</button>
  </ng-template>
  <kendo-grid-excel fileName="{{ this.title }}.xlsx" [fetchData]="allData"></kendo-grid-excel>
  <kendo-grid-column
    *ngFor="let column of gridData == undefined ? [] : gridData.columns"
    field="{{ column.columnName }}"
    title="{{ column.title }}"
    [width]="200"></kendo-grid-column>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="kendoGridProperties.navigatorCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="kendoGridProperties.pageSizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>
