import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '../models/client.model';
import { BaseService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { WorkArea } from '../models/workarea.model';

const selectedClientKey = 'selectedClient';
const selectedWorkArea = 'selectedWorkArea';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService {
  public readonly getClientsUrl = this.appSettings.baseApiUrl.concat('/api/workareaclient/Clients');
  public readonly getWorkAreasUrl = this.appSettings.baseApiUrl.concat('/api/workareaclient/WorkAreas');
  private selectedClientSubject: BehaviorSubject<Client>;
  private selectedWorkAreaSubject: BehaviorSubject<WorkArea>;
  public selectedClient$: Observable<Client>;
  public selectedWorkArea$: Observable<WorkArea>;

  constructor(private injector: Injector) {
    super(injector);
    this.selectedClientSubject = new BehaviorSubject<Client>(this.getCurrentClientFromLocalStorage());
    this.selectedWorkAreaSubject = new BehaviorSubject<WorkArea>(this.getCurrentWorkAreaFromLocalStorage());
    this.selectedClient$ = this.selectedClientSubject.asObservable();
    this.selectedWorkArea$ = this.selectedWorkAreaSubject.asObservable();
  }

  public getclients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.getClientsUrl);
  }

  public getWorkAreas(clientId: number): Observable<WorkArea[]> {
    const params = new HttpParams().set('clientId', clientId);
    return this.http.get<any[]>(this.getWorkAreasUrl, { params });
  }

  public getCurrentClientFromLocalStorage(): Client {
    const clientStr = localStorage.getItem(selectedClientKey);
    return clientStr ? JSON.parse(atob(clientStr)) : null;
  }

  public getCurrentWorkAreaFromLocalStorage(): WorkArea {
    const workAreaStr = localStorage.getItem(selectedWorkArea);
    return workAreaStr ? JSON.parse(atob(workAreaStr)) : null;
  }

  public setSelectedClient(client: Client) {
    localStorage.setItem(selectedClientKey, btoa(JSON.stringify(client)));
    this.selectedClientSubject.next(client);
  }

  public setSelectedWorkArea(workArea: WorkArea) {
    localStorage.setItem(selectedWorkArea, btoa(JSON.stringify(workArea)));
    this.selectedWorkAreaSubject.next(workArea);
  }

  public clearSelectedClient(): void {
    localStorage.removeItem(selectedClientKey);
    this.selectedClientSubject.next(null);
  }

  public clearSelectedWorkArea(): void {
    localStorage.removeItem(selectedWorkArea);
    this.selectedWorkAreaSubject.next(null);
  }
}
