import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public defaultVisible: boolean;
  public privacyVisible = false;
  public termsVisible = false;

  showScrollbarModes: any[];
  content: string;
  updateContentTimer: number;
  scrollByContent = true;
  scrollByThumb = true;
  scrollbarMode: string;
  pullDown = false;

  constructor() {
    this.defaultVisible = false;

    this.showScrollbarModes = [
      {
        text: 'On Scroll',
        value: 'onScroll'
      },
      {
        text: 'On Hover',
        value: 'onHover'
      },
      {
        text: 'Always',
        value: 'always'
      },
      {
        text: 'Never',
        value: 'never'
      }
    ];

    this.scrollbarMode = this.showScrollbarModes[0].value;
  }

  toggleDefault() {
    this.defaultVisible = !this.defaultVisible;
  }

  showTermsOfUse() {
    this.termsVisible = true;
  }

  showPrivacy() {
    this.privacyVisible = true;
  }
}
