<kendo-dialog (close)="close()" [class]="modalSizeClass">
  <kendo-dialog-titlebar>
    <div class="row" style="width: 100%">
      <div class="col vertical-center page-header">{{ modalTitle }}</div>
      <div class="col buttons" style="display: flex">
        <button (click)="manageSelection(true)" class="btn btn-primary mr-3">Select All</button>
        <button (click)="manageSelection(false)" class="btn btn-primary mr-3">Reset</button>
        <button (click)="triggerAction()" class="btn btn-primary">{{ action }}</button>
      </div>
    </div>
  </kendo-dialog-titlebar>

  <div class="card card-white">
    <div class="dialog-frame">
      <form>
        <div class="checkbox-container">
          <div *ngFor="let element of iteratorData | keyvalue" class="checkbox-item">
            <input
              #DialogCheck
              type="checkbox"
              kendoCheckBox
              [name]="element.key"
              [(ngModel)]="selectionDict[element.key]" />
            <kendo-label [for]="DialogCheck" class="k-checkbox-label">{{ element.value }}</kendo-label>
          </div>
        </div>
      </form>
    </div>
  </div>
</kendo-dialog>
