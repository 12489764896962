import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Columns, GenericGridProperties } from 'src/app/core/models/utils.models';
import { ImportExportService } from 'src/app/core/services/import-export.service';
import { GridUtils } from 'src/utils/gridUtils';
import { kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-generic-validation-grid',
  templateUrl: './generic-validation-grid.component.html',
  styleUrls: ['./generic-validation-grid.component.scss']
})
export class GenericValidationGridComponent implements OnInit {
  @Input() inputData: GenericGridProperties;
  public validationData: any;
  public isLoading: boolean = true;
  public kendoGridProperties = kendoGridProperties;
  @Output() closeValidationDialog = new EventEmitter<void>();

  public dataColumns: Columns[];
  constructor(private importExportService: ImportExportService) {
    this.gridExcelExport = this.gridExcelExport.bind(this);
  }

  ngOnInit(): void {
    if (this.inputData.isValidationData) {
      this.importExportService
        .getImportData(this.inputData.data.TableName, this.inputData.data.TramImportId)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((response: any) => {
          if (!response.error) {
            this.validationData = response.data;
            this.dataColumns = response.columns;
          }
        });
    } else {
      this.validationData = this.inputData.data;
      this.dataColumns = this.inputData.columns;
      this.isLoading = false;
    }
  }

  public gridExcelExport() {
    return GridUtils.gridExcelExport(this.validationData);
  }
}
