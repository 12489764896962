import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  private readonly dateFormat = 'dd MMM yyyy h:mm:ss a'; // Your global date format
  constructor(private datePipe: DatePipe) {}

  transform(value: any): any {
    return this.datePipe.transform(value, this.dateFormat) || '';
  }
}
