import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AppSettingsService } from './core/services/app-settings.service';
import { HomeComponent } from './modules/home/home/home.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { GosystemModule } from './modules/gosystem/gosystem.module';

const appInitializerFn = (appSetting: AppSettingsService) => {
  return () => {
    return appSetting.loadAppSettings();
  };
};

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    GridModule,
    BrowserAnimationsModule,
    DropDownsModule,
    SharedModule,
    FormsModule,
    NotificationModule,
    GosystemModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppSettingsService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
