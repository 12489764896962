<footer class="footer">
  <div class="copyright-text">
    <p class="mb-0">
      <a id="TOS" (mouseenter)="toggleDefault()" (mouseleave)="toggleDefault()"
        >Copyright © 2020 Deloitte Development LLC.</a
      >
      All rights reserved.
    </p>
  </div>
  <ul class="footer-links">
    <li><a (click)="showTermsOfUse()">Terms Of Use</a></li>
    <li><a (click)="showPrivacy()">Privacy</a></li>
    <li><a href="mailto:ustaxctosupport@deloitte.com">Contact Us</a></li>
  </ul>
</footer>

<!-- <dx-popover
                target="#TOS" 
                position="top"
                [width]="720"
                [(visible)]="defaultVisible">
                <div *dxTemplate="let data = model of 'content'" class="p-3">
                    © 2020. See Terms of Use for more information. Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee (“DTTL”), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as “Deloitte Global”) does not provide services to clients. Please see <a href="http://www.deloitte.com/about">www.deloitte.com/about</a> to learn more about our global network of member firms.
                </div>
</dx-popover> -->
<!-- 
<dx-popup
        [width]="800"
        [height]="600"
        [showTitle]="true"
        title="Terms of Use"
        [dragEnabled]="false"
        [closeOnOutsideClick]="true"
        [(visible)]="termsVisible">

            <dx-scroll-view #scrollView
            id="scrollview">
            <app-footer-terms-of-use></app-footer-terms-of-use>
        </dx-scroll-view>
</dx-popup> -->

<!-- <dx-popup
        [width]="800"
        [height]="600"
        [showTitle]="true"
        title="Privacy"
        [dragEnabled]="false"
        [closeOnOutsideClick]="true"
        [(visible)]="privacyVisible">

            <dx-scroll-view #scrollView
            id="scrollview">
            <app-footer-privacy></app-footer-privacy>
        </dx-scroll-view>
</dx-popup> -->
