<nav class="d-navbar d-flex fixed-header">
  <div class="brand d-flex" [ngClass]="{ 'sidebar-collapsed': isSidebarOpened }">
    <div class="logo">
      <a class="navbar-brand">
        <img class="deloitte-logo-icon" [ngClass]="{ 'd-none': !isSidebarOpened }" src="./assets/images/d-logo.svg" />
        <img class="deloitte-logo" [ngClass]="{ 'd-none': isSidebarOpened }" src="./assets/images/deloitte-logo.svg" />
      </a>
    </div>
    <div class="toggle-sidebar-btn" (click)="toggle()" [ngClass]="{ 'toggle-rotate': isSidebarOpened }">
      <i class="fas fa-chevron-double-left"></i>
    </div>
  </div>

  <div class="nav-selection">
    <div id="tramLogo" class="ml-5">
      <span> <a (click)="redirectToHome()">TRAM</a></span>
    </div>
    <div *ngIf="!hideClientSelection()" class="col-md-5 d-flex ml-2">
      <div class="col-md-6">
        <kendo-dropdownlist
          [data]="clientList$ | async"
          textField="clientName"
          valueField="clientId"
          [valuePrimitive]="true"
          [(ngModel)]="selectedClientId"
          (valueChange)="clientChange()"
          [kendoDropDownFilter]="kendoFilterSettings"
          [filterable]="true"
          class="p-dropdownlist">
        </kendo-dropdownlist>
      </div>
      <div class="col-md-6 ml-1">
        <kendo-dropdownlist
          [data]="workareaList$ | async"
          [valuePrimitive]="true"
          textField="workAreaName"
          valueField="workAreaId"
          [(ngModel)]="selectedWorkAreaId"
          (valueChange)="workareaChange()"
          [kendoDropDownFilter]="kendoFilterSettings"
          [filterable]="true"
          class="p-dropdownlist">
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <div *ngIf="profile" class="ml-auto d-xl-flex d-none mr-3">
    <app-profile [profile]="profile" (logout)="logout()"></app-profile>
  </div>
</nav>
<div *ngIf="showClientModal">
  <app-client-selection-modal
    [clientSelected]="clientSelected"
    (close)="showClientModal = false"></app-client-selection-modal>
</div>
