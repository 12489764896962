import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { LayoutModule } from '../shared/layout/layout.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserModule, LayoutModule],
  exports: [LayoutModule]
})
export class CoreModule {}
