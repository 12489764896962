import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DataSetService } from 'src/app/core/services/data-set.service';
import { FieldService } from 'src/app/core/services/field.service';
import { FileUtils } from 'src/utils/fileUtils';
import { fieldTypes, kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-resource-version',
  templateUrl: './resource-version.component.html',
  styleUrls: ['./resource-version.component.scss']
})
export class ResourceVersionComponent implements OnInit {
  @Input() resource: any;
  public resourceVersions: any;
  public isLoading: boolean = true;
  public kendoGridProperties = kendoGridProperties;
  public downloadingVersions: number[] = [];
  @Output() closeResourceDialog = new EventEmitter<void>();

  constructor(
    private datasetService: DataSetService,
    private fieldService: FieldService
  ) {}

  ngOnInit(): void {
    this.datasetService
      .getResourceVersion(this.resource.resourceName)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((response: any) => {
        this.resourceVersions = response;
      });
  }

  isFieldVersion() {
    return fieldTypes.includes(this.resource?.resourceName.toLowerCase());
  }

  downloadVersionReport(version: any) {
    this.downloadingVersions.push(version.versionId);
    this.datasetService
      .exportVerisonData(version.versionId, this.resource.resourceName, this.resource.displayName)
      .subscribe((response) => {
        FileUtils.exportData(response, 'report.xslx');
        let index = this.downloadingVersions.indexOf(version.versionId);
        if (index > -1) {
          this.downloadingVersions.splice(index, 1);
        }
      });
  }

  downloadFieldVersionReport(version: any) {
    this.downloadingVersions.push(version.versionId);
    this.fieldService.exportVerisonData(version.versionId, this.resource.resourceName).subscribe((response) => {
      FileUtils.exportData(response, 'report.xslx');
      let index = this.downloadingVersions.indexOf(version.versionId);
      if (index > -1) {
        this.downloadingVersions.splice(index, 1);
      }
    });
  }

  isDownloading(version) {
    return this.downloadingVersions.indexOf(version.versionId) > -1;
  }
}
