import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EFileAttachments } from '../models/efile.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {
  readonly taskServiceUrl = this.appSettings.baseApiUrl.concat('/api/task/');
  private readonly gsSaveUrl = this.appSettings.baseApiUrl.concat('/api/gsSave/');
  public refresh$: Subject<boolean> = new Subject();

  public taskProcessRefresh$: Subject<boolean> = new Subject();

  constructor(private injector: Injector) {
    super(injector);
  }

  public submitCalcTask(tramIds: number[]): Observable<any> {
    const requestBody = tramIds;
    return this.http.post<any>(`${this.taskServiceUrl}CalcTask`, requestBody);
  }

  public submitEntityReportTask(tramIds: number[], reportType: string): Observable<any> {
    const requestBody = {
      ids: tramIds,
      reportType: reportType
    };
    return this.http.post<any>(`${this.taskServiceUrl}CreateEntityReportTask`, requestBody);
  }

  public createTramTask(taskTypeId: number) {
    const params = new HttpParams().set('taskTypeId', taskTypeId);
    return this.http.post<any>(`${this.taskServiceUrl}CreateTramTask`, null, { params });
  }

  public cancelTask(taskTypeId: number, taskIds: number[], comments: string = '') {
    const requestBody = {
      taskTypeId: taskTypeId,
      ids: taskIds,
      comments: comments
    };
    return this.http.post<any>(`${this.taskServiceUrl}CancelTask`, requestBody);
  }

  public cancelTasks(tasks: any, comments: string) {
    const requestBody = {
      tasks,
      comments: comments
    };
    return this.http.post<any>(`${this.taskServiceUrl}CancelTasks`, requestBody);
  }

  public updateTaskPriority(tasks: any, priority: number) {
    const requestBody = {
      tasks,
      priority: priority
    };
    return this.http.post<any>(`${this.taskServiceUrl}UpdateTaskPriority`, requestBody);
  }

  public getLatestTasks(inProcess: boolean = true): Observable<any> {
    const params = new HttpParams().set('inProcess', inProcess);
    return this.http.get(`${this.taskServiceUrl}TaskDashboard`, { params });
  }

  public taskProcessRefresh() {
    this.taskProcessRefresh$.next(true);
  }

  public isLoading = false;

  public getTramTasks(tramIds?: number[], taskTypeId: number = 0): Observable<any> {
    const requestBody = { ids: tramIds, taskTypeId: taskTypeId };
    return this.http.post(`${this.taskServiceUrl}GetTramTasks`, requestBody);
  }

  public refresh() {
    this.refresh$.next(true);
  }

  public createPublish(printIds: number[]) {
    const requestBody = {
      ids: printIds
    };
    return this.http.post<any>(`${this.gsSaveUrl}CreatePublish`, requestBody);
  }

  public createPrintSignatureTasks(printIds: number[]) {
    const requestBody = {
      ids: printIds
    };
    return this.http.post<any>(`${this.gsSaveUrl}CreatePrintSignatureTasks`, requestBody);
  }

  public createEfileAttachmentsTask(tramIds: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post<any>(`${this.taskServiceUrl}CreateEFileAttachmentsTask`, requestBody);
  }

  public createEfileDiagnosticsTask(tramIds: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post<any>(`${this.taskServiceUrl}CreateEFileDiagnosticsTask`, requestBody);
  }

  public createEfileMetricsTask(tramIds: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post<any>(`${this.taskServiceUrl}CreateEFileMetricsTask`, requestBody);
  }

  public saveObligations(tramIds: number[], obligationType: string): Observable<any> {
    const requestBody = {
      tramIds: tramIds,
      obligationType: obligationType
    };
    return this.http.post(`${this.gsSaveUrl}SaveObligations`, requestBody);
  }

  public saveForms(tramIds: number[], forms: string[]): Observable<any> {
    const requestBody = {
      tramIds: tramIds,
      forms: forms
    };
    return this.http.post<any>(`${this.gsSaveUrl}SaveForms`, requestBody);
  }

  public createEFileObligation(tramIds: number[], obligationType: string): Observable<any> {
    const requestBody = {
      tramIds: tramIds,
      obligationType: obligationType
    };
    return this.http.post(`${this.taskServiceUrl}CreateEFileObligation`, requestBody);
  }

  public createEFileTask(tramIds: number[], efileJurisdiction: string[], efileType: string): Observable<any> {
    const requestBody = {
      tramIds: tramIds,
      efileJurisdiction: efileJurisdiction,
      efileType: efileType
    };
    return this.http.post(`${this.taskServiceUrl}CreateEFileTask`, requestBody);
  }
  public createExcelToPdfTask(
    tramId: number,
    calcTaskId: number,
    mappingIds: number[],
    reportType: string
  ): Observable<any> {
    const requestBody = { tramId: tramId, calcTaskId: calcTaskId, mappingIds: mappingIds, reportType: reportType };
    return this.http.post(`${this.taskServiceUrl}CreateExcelToPdfTask`, requestBody);
  }

  public saveEfileAttachmentTask(attachments: EFileAttachments[]) {
    return this.http.post<any>(`${this.taskServiceUrl}SaveEFileAttachemntTask`, attachments);
  }

  public clearFolderDir() {
    return this.http.get<any>(`${this.taskServiceUrl}DeleteTempFolder`);
  }

  public removeEFileAttachment(fileNames: string): Observable<any> {
    const formData = new FormData();
    formData.append('fileNames', fileNames);
    return this.http.post<any>(`${this.taskServiceUrl}DeleteTempFolderFile`, formData);
  }

  public retrieveEOrgFieldValues(tramIds: number[], jsonData: string[], isAllForms: boolean = false): Observable<any> {
    const requestBody = {
      tramIds: tramIds,
      jsonData: jsonData
    };
    const params = new HttpParams().set('isAllForms', isAllForms);
    return this.http.post<any>(`${this.taskServiceUrl}CreateEOrgFieldValuesTask`, requestBody, { params });
  }

  public createCEReportsTask(reportsList: string[], isAllReports: boolean = false): Observable<any> {
    const requestBody = { reportsList: reportsList };
    const params = new HttpParams().set('isAllReports', isAllReports);
    return this.http.post<any>(`${this.taskServiceUrl}CreateCrossEntityReportTask`, requestBody, { params });
  }

  public createGlobalTask(tableName: string) {
    const params = new HttpParams().set('tableName', tableName);
    return this.http.post<any>(`${this.taskServiceUrl}CreateGlobalTask`, null, { params });
  }

  public cancelGlobalTask(taskTypeId: number, taskId: number, comments: string) {
    const params = new HttpParams().set('taskTypeId', taskTypeId).set('taskId', taskId).set('comments', comments);
    return this.http.post<any>(`${this.taskServiceUrl}CancelGlobalTask`, null, { params });
  }
}
