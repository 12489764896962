<kendo-dialog title="{{ title }}" (close)="onClose()" class="import-dialog-sizing">
  <div class="upload-wrapper">
    <kendo-uploaddropzone zoneId="filesImport"> 'Supported file types: XLSX' </kendo-uploaddropzone>
    <kendo-fileselect
      #fileSelect
      zoneId="filesImport"
      (select)="onFileSelect($event)"
      (remove)="onFileRemove($event)"
      [restrictions]="fileRestrictions"
      [multiple]="false">
    </kendo-fileselect>
  </div>
  <kendo-dialog-actions>
    <div class="d-flex justify-content-end">
      <button (click)="isFieldData ? importFields() : import()" class="btn btn-primary mx-3">
        <i class="fa-sharp fa-regular fa-xmark"></i> Upload
      </button>
      <button (click)="onClose()" class="btn btn-light mx-3"><i class="fa-sharp fa-regular fa-xmark"></i> Close</button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- Validation Grid-->
<app-generic-validation-grid
  [inputData]="importResponse"
  *ngIf="renderValidationDialog"
  (closeValidationDialog)="this.renderValidationDialog = false"></app-generic-validation-grid>
