import { Component, Input, TemplateRef, ContentChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() title: string | TemplateRef<any> | undefined;
  @Input() subTitle: string | TemplateRef<any> | undefined;
  @Input() showBackBtn = true;
  @Input() backUrl: string | undefined;
  @ContentChild('titleTemplate') titleTemplate: TemplateRef<any> | undefined;
  @ContentChild('subTitleTemplate') subTitleTemplate: TemplateRef<any> | undefined;

  constructor(
    private location: Location,
    private router: Router
  ) {}

  public goBack() {
    if (this.backUrl) {
      this.router.navigateByUrl(this.backUrl);
    } else {
      this.location.back();
    }
  }
}
