import { Injectable } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State } from '@progress/kendo-data-query';
import { GridFilters } from 'src/app/core/models/utils.models';

@Injectable({
  providedIn: 'root'
})
export class GridUtils {
  public static getFilters(state: State) {
    let filters: GridFilters[] = [];
    state.filter?.filters.forEach((f: any) => {
      let filterData = {
        'columnName': f.filters[0].field,
        'value': f.filters[0].value,
        'condition': f.filters[0].operator
      };
      filters.push(filterData);
    });
    return filters;
  }

  public static getSortObject(state: State) {
    if (state.sort === undefined || state.sort?.length === 0) {
      return {};
    }
    return { columnName: state.sort[0].field, sortOrder: state.sort[0].dir };
  }

  public static gridExcelExport(exportData): any {
    const result: ExcelExportData = {
      data: exportData
    };
    return result;
  }
}
