<kendo-dialog-titlebar>
  <div class="vertical-center page-header">Micro Services</div>
  <div class="col-md-11 text-right">
    <button (click)="deactivateService()" class="btn btn-primary mr-3">Deactivate</button>
    <button (click)="activateService()" class="btn btn-primary mr-3">Activate</button>
  </div>
</kendo-dialog-titlebar>
<kendo-grid
  [kendoGridBinding]="microServicesData"
  kendoGridSelectBy="id"
  [(selectedKeys)]="selectedIds"
  filterable="menu"
  [resizable]="true"
  [loading]="isLoading"
  [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
  class="dialog-grid-sizing">
  <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
  <kendo-grid-column field="appName" title="Service Name"></kendo-grid-column>
  <kendo-grid-column field="hostName" title="Host Name" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="!dataItem.isOnline" class="fa fa-circle" style="color: #d9534f"></div>
      <div *ngIf="dataItem.isOnline" class="fa fa-circle" style="color: #4cae4c"></div>
      <span style="padding-left: 15px">{{ dataItem.hostName }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="heartBeatTS" title="Last Heart Beat">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.heartBeatTS | dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="appId" title="Status">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.isActive"><span class="fa fa-check"></span> Active</div>
      <div *ngIf="!dataItem.isActive"><span class="fa fa-times"></span> Inactive</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments"> </kendo-grid-column>
  <kendo-grid-column field="updatedTS" title="Updated On">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.updatedTS | dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
</kendo-grid>

<app-prompt-dialog
  *ngIf="showPromptDialog"
  (closeClick)="this.showPromptDialog = false"
  [title]="dialogTitle"
  [message]="dialogContent"
  [label]="dialogLabel"
  (yesClick)="updateServiceStatus(false, $event)"
  (noClick)="this.showPromptDialog = false">
</app-prompt-dialog>

<app-confirm-dialog
  *ngIf="showConfirmDialog"
  (closeClick)="this.showConfirmDialog = false"
  [title]="dialogTitle"
  [message]="dialogContent"
  (yesClick)="updateServiceStatus(true)"
  (noClick)="this.showConfirmDialog = false">
</app-confirm-dialog>
