import { NgModule } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { MenusModule } from '@progress/kendo-angular-menu';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

@NgModule({
  declarations: [],
  imports: [
    ButtonsModule,
    DropDownsModule,
    DialogModule,
    ExcelModule,
    GridModule,
    UploadsModule,
    InputsModule,
    LabelModule,
    MenusModule,
    TooltipModule,
    ProgressBarModule,
    TreeViewModule,
    LayoutModule,
    IndicatorsModule,
    DateInputsModule
  ],
  exports: [
    ButtonsModule,
    DropDownsModule,
    DialogModule,
    ExcelModule,
    GridModule,
    UploadsModule,
    InputsModule,
    LabelModule,
    MenusModule,
    TooltipModule,
    ProgressBarModule,
    TreeViewModule,
    LayoutModule,
    IndicatorsModule,
    DateInputsModule
  ]
})
export class KendoModule {}
