import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../models/app-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  protected appSettings: AppSettings;

  constructor(private http: HttpClient) {}

  public loadAppSettings() {
    const stringToBoolean = (str: string): boolean => str.toLowerCase() == 'true';
    const stringToNumber = (str: string): number => +str;

    this.appSettings = {
      baseApiUrl: environment.baseApiUrl,
      azureADAuthority: environment.azureADAuthority,
      azureADClientId: environment.azureADClientId,
      azureADRedirectUrl: environment.azureADRedirectUrl,
      azureADPostLogoutRedirectUri: environment.azureADPostLogoutRedirectUri,
      azureADSilentRedirectUri: environment.azureADSilentRedirectUri,
      timeout: environment.timeout
    };

    return this.appSettings;
  }

  public getAllSettings(): AppSettings {
    return this.appSettings;
  }

  public get(key: string): string | number | boolean {
    return this.appSettings[key];
  }
}
