//replace the contents from the enviroment.local.ts while debugging
export const environment = {
  production: true,
  baseApiUrl: '#{BaseApiUrl}#',
  azureADAuthority: '#{ADAuthority}#',
  azureADClientId: '#{ADClientId}#',
  azureADRedirectUrl: '#{ADRedirectUri}#',
  azureADPostLogoutRedirectUri: '#{ADLogoutUri}#',
  azureADSilentRedirectUri: '#{ADRedirectUri}#',
  timeout: 3600,
  cookieSrc: '#{Cookie:Src}#',
  cookieDomainScript: '#{Cookie:DomainScript}#'
};
