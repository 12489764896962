<nav class="d-navbar d-flex fixed-header">
  <div class="brand d-flex">
    <div class="logo">
      <a class="navbar-brand" routerLink="/">
        <img class="deloitte-logo" src="./assets/images/deloitte-logo.svg" />
      </a>
    </div>
  </div>

  <div class="nav-selection">
    <div id="tramLogo" class="ml-5">
      <span><a (click)="refreshPage()">TRAM</a></span>
    </div>
  </div>
  <div class="xl-flex mr-4" style="margin-top: 20px">
    <span
      ><b>{{ this.gsFirm }}-{{ this.gsAccount }}</b></span
    >
  </div>
</nav>
