export class UserContext {
  sessionId: string;
  contextId: string;

  constructor(sessionId: string, contextId: string) {
    this.sessionId = sessionId;
    this.contextId = contextId;
  }

  public toString(): string {
    return JSON.stringify({ sessionId: this.sessionId, contextId: this.contextId });
  }
}

export class UserContextDetails {
  public clientId: number;
  public workAreaId: number;
  public contextId: string;
  public userTypeId: number;
}
