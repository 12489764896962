<kendo-dialog class="dialog-window" (close)="close()" [width]="580">
  <kendo-dialog-titlebar class="dialog-title vertical-center page-header">{{ title }}</kendo-dialog-titlebar>
  <div class="dialog-message">
    <p>{{ message }}</p>

    <div class="form-group row" style="margin-top: 30px">
      <label class="col-sm-2 col-form-label">{{ label }}</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" style="width: 325px" [(ngModel)]="textFieldValue" />
      </div>
    </div>
  </div>
  <kendo-dialog-actions [layout]="actionsLayout" style="border-color: white">
    <button kendoButton class="confirm-btn" (click)="onYes()">Yes</button>
    <button kendoButton class="cancel-btn" (click)="onNo()">No</button>
  </kendo-dialog-actions>
</kendo-dialog>
