export class User {
  sessionId: string;
  uid: string;
  email: string;
  userName: string;
  lastName: string;
  firstName: string;
  tou: string;
}

export class UserDetails {
  userId: number;
  email: string;
  userName: string;
  uniqueName: string;
  displayName: string;
  location: string;
}
