<div class="main-nav-container" [ngClass]="{ 'collapsed': isSidebarCollapsed }">
  <ul class="main-nav">
    <li
      class="main-nav-item"
      routerLinkActive="router-link-active"
      *ngFor="let level1Item of navItems; let i = index"
      [class]="level1Item.styleClass">
      <!-- Adding Router link only if URL is specified -->
      <a
        class="menu-toggle"
        [routerLink]="[level1Item.url]"
        *ngIf="level1Item.url?.length > 0 && userAccessAllowed(level1Item)">
        <span class="icon"><i class="fa-fw" [class]="level1Item.icon"></i></span>
        <span class="nav-name">{{ level1Item.text }}</span>
        <span class="subnav-arrow" *ngIf="level1Item.items.length > 0">
          <span class="k-icon k-i-arrow-chevron-right"></span>
        </span>
      </a>
      <a class="menu-toggle" *ngIf="level1Item.url?.length == 0 && userAccessAllowed(level1Item)">
        <span class="icon"><i class="fa-fw" [class]="level1Item.icon"></i></span>
        <span class="nav-name">{{ level1Item.text }}</span>
        <span class="subnav-arrow" *ngIf="level1Item.items.length > 0">
          <span class="k-icon k-i-arrow-chevron-right"></span>
        </span>
      </a>
      <ul class="workspace-sub-group" *ngIf="level1Item.items.length > 0">
        <span class="level1Label">{{ level1Item.text }}</span>
        <li
          class="workspace-submenu-item"
          routerLinkActive="router-link-active"
          *ngFor="let level2Item of level1Item.items"
          [class.disable]="
            level2Item.text === 'Validate Users' ||
            level2Item.text === 'Dev Dashboard' ||
            level2Item.text === 'Import All Fields' ||
            level2Item.text === 'Signatures' ||
            level2Item.text === 'GS Token'
          ">
          <!--once these are implemented, this [class.disabled] can be removed !-->
          <a
            *ngIf="!level2Item.enableAction && level1Item.text !== 'Privacy and Legal' && userAccessAllowed(level2Item)"
            [routerLink]="level2Item.url">
            <span class="icon"></span>
            <span class="nav-name">{{ level2Item.text }}</span>
          </a>
          <a
            (click)="loadComponent(level2Item.action)"
            *ngIf="level2Item.enableAction && userAccessAllowed(level2Item)">
            <span class="icon"></span>
            <span class="nav-name">{{ level2Item.text }}</span>
          </a>
          <a
            *ngIf="level1Item.text == 'Privacy and Legal'"
            [href]="level2Item.url"
            target="_blank"
            [class]="level2Item.styleClass">
            <span><i class="fa-fw" [class]="level2Item.icon"></i></span>
            <span class="nav-name">{{ level2Item.text }}</span>
          </a>
        </li>
      </ul>
      <ul class="workspace-sub-group individual-nodes" style="padding: 0px" *ngIf="level1Item.items.length == 0">
        <span class="level1Label">{{ level1Item.text }}</span>
      </ul>
    </li>
  </ul>
</div>

<div kendoDialogContainer></div>
