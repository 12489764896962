import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

import { AdminService } from 'src/app/core/services/admin.service';
import { NotifierService } from 'src/app/core/services/notifier.service';

import { MicroServices } from 'src/app/core/models/micro-services.model';
import { kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-micro-services',
  templateUrl: './micro-services.component.html',
  styleUrls: ['./micro-services.component.scss']
})
export class MicroServicesComponent extends DialogContentBase implements OnInit {
  public microServicesData: MicroServices[];
  public kendoGridProperties = kendoGridProperties;
  public status: string = '';
  public isLoading: boolean = false;
  public dialogTitle: string;
  public dialogContent: string;
  public dialogLabel: string;
  public showPromptDialog: boolean = false;
  public showConfirmDialog: boolean = false;
  public selectedIds: number[];
  public opened: boolean = true;
  close: any;

  constructor(
    private adminService: AdminService,
    private notifierService: NotifierService,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit() {
    this.getMicroServices();
  }

  public getMicroServices(): void {
    this.isLoading = true;
    this.adminService
      .getServicesGrid()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((resp) => {
        this.microServicesData = resp;
      });
  }

  deactivateService() {
    if (this.selectedIds == undefined || this.selectedIds.length < 1) {
      this.notifierService.notifyByType('Error. Please select a service to deactivate.', 'error', 5);
      return;
    }
    this.showPromptDialog = true;
    this.dialogTitle = 'Confirm Service De-Activation';
    this.dialogContent = 'Are you sure you want to de-activate this Service?';
    this.dialogLabel = 'Comments:';
  }

  activateService() {
    if (this.selectedIds == undefined || this.selectedIds.length < 1) {
      this.notifierService.notifyByType('Error. Please select a service to activate.', 'error', 5);
      return;
    }
    this.showConfirmDialog = true;
    this.dialogTitle = 'Confirm Service Activation';
    this.dialogContent = 'Are you sure you want to activate this Service?';
  }

  updateServiceStatus(status: boolean, comments?: string) {
    this.adminService.updateService(this.selectedIds, comments, status).subscribe((response) => {
      this.notifierService.notify(response.message, response.error);
      this.getMicroServices();
    });
    if (status) {
      this.showConfirmDialog = false;
      this.selectedIds = [];
    } else {
      this.showPromptDialog = false;
      this.selectedIds = [];
    }
  }
}
