<kendo-dialog class="dialog-window" (close)="close()" [width]="580">
  <kendo-dialog-titlebar class="dialog-title vertical-center page-header">{{ title }}</kendo-dialog-titlebar>
  <div class="dialog-message">
    <p>{{ message }}</p>
  </div>
  <kendo-dialog-actions [layout]="actionsLayout" style="border-color: white">
    <button kendoButton class="confirm-btn" (click)="onYes()">{{ confirmBtnText }}</button>
    <button kendoButton class="cancel-btn" (click)="onNo()">{{ cancelBtnText }}</button>
  </kendo-dialog-actions>
</kendo-dialog>
