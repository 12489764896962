import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { TermsOfUseComponent } from './footer/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './footer/privacy/privacy.component';
import { ProfileComponent } from './header/profile/profile.component';
import { ClientLayoutComponent } from './client-layout/client-layout.component';
import { ErrorComponent } from './error/error.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ClientSelectionComponent } from './client-selection/client-selection.component';
import { ClientSelectionModalComponent } from './header/client-selection-modal/client-selection-modal.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { GlobalLayoutComponent } from './global-layout/global-layout.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TermsOfUseComponent,
    PrivacyComponent,
    ProfileComponent,
    ClientLayoutComponent,
    SideNavComponent,
    ErrorComponent,
    PageHeaderComponent,
    ClientSelectionComponent,
    ClientSelectionModalComponent,
    GlobalLayoutComponent
  ],
  imports: [CommonModule, RouterModule, SharedModule, TooltipsModule, DropDownsModule, FormsModule, DialogModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    PageHeaderComponent,
    ClientSelectionComponent,
    ClientSelectionModalComponent
  ]
})
export class LayoutModule {}
