import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActionsLayout } from '@progress/kendo-angular-dialog';
@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  constructor() {}
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() label: string = '';

  @Output() yesClick = new EventEmitter<string>();
  @Output() noClick = new EventEmitter<void>();
  @Output() closeClick = new EventEmitter<void>();
  public actionsLayout: ActionsLayout = 'end';
  public opened: boolean = false;
  public textFieldValue: string;

  public onYes() {
    this.yesClick.emit(this.textFieldValue);
  }
  public onNo(): void {
    this.noClick.emit();
  }
  public close(): void {
    this.closeClick.emit();
  }
}
