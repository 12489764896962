import { HeaderRow, ImportEnum } from 'src/utils/tramConstants';

export class ImportDto {
  dataLoadEnum?: ImportEnum = ImportEnum.merge;
  headerRow?: number = HeaderRow.dataSourceHeaderRow;
  tableName: string;
}

export class ImportFieldDto {
  api: string;
  formId: number;
  profileId?: number = 0;
}
