import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { FileInfo, FileRestrictions, FileSelectComponent } from '@progress/kendo-angular-upload';
import { EFileAttachments } from 'src/app/core/models/efile.model';

import { NotifierService } from 'src/app/core/services/notifier.service';
import { TaskService } from 'src/app/core/services/task.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { finalize } from 'rxjs/operators';
import { EFileService } from 'src/app/core/services/efile.service';
import { TRAuthService } from 'src/app/core/services/tr-auth.service';
import { eFileSizeBytes, kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-efile-import',
  templateUrl: './efile-import.component.html',
  styleUrls: ['./efile-import.component.scss']
})
export class EfileImportComponent extends DialogContentBase implements OnInit {
  @ViewChild('fileSelect', { static: true }) fileSelect: FileSelectComponent;
  public files: FileInfo[] = [];
  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: eFileSizeBytes
  };
  public reviewUploadedEfileGrid: boolean = false;
  saveUrl: string;
  removeUrl: string;

  public uploadedEfiles: EFileAttachments[];
  public selectedlocator: string[] = [];
  public kendoGridProperties = kendoGridProperties;
  public isLoading: boolean = false;

  public disableActionBtns = true;
  public disableSubmitBtn = true;

  constructor(
    private efileService: EFileService,
    private notifierService: NotifierService,
    private efileTask: TaskService,
    private trAuthService: TRAuthService,
    dialog: DialogRef
  ) {
    super(dialog);
  }
  ngOnInit() {
    this.saveUrl = this.efileTask.taskServiceUrl.concat('SaveUploadedFile');
    this.removeUrl = this.efileTask.taskServiceUrl.concat('DeleteTempFolderFile');
    this.allData = this.allData.bind(this);
  }

  clearFiles() {
    this.efileTask.clearFolderDir().subscribe();
    this.files = [];
    this.disableActionBtns = true;
  }

  closeReviewDialog() {
    this.reviewUploadedEfileGrid = false;
    this.clearFiles();
  }

  public getUploadedEFiles(): void {
    this.reviewUploadedEfileGrid = true;
    this.isLoading = true;
    this.efileService
      .getUploadedEFiles()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((resp) => {
        this.uploadedEfiles = resp;
        this.disableSubmitBtn =
          this.uploadedEfiles === undefined ||
          this.uploadedEfiles.length === 0 ||
          this.uploadedEfiles.some((item) => item['comments'] !== '' && item['comments'] !== undefined);
      });
  }

  public allData(): any {
    const result: ExcelExportData = {
      data: this.uploadedEfiles
    };
    return result;
  }

  removeAttachement(dataItem: any) {
    this.efileTask.removeEFileAttachment(dataItem.attachmentName).subscribe((res) => {
      this.notifierService.notify(res.message, res.error);
      this.getUploadedEFiles();
    });
  }

  saveEfileAttachment(): void {
    this.efileTask.saveEfileAttachmentTask(this.uploadedEfiles).subscribe((res) => {
      this.notifierService.notifyByType(res.message, res.error ? 'error' : 'success');
      location.href = 'work-area/efile/upload-history';
      this.dialog.close();
      this.clearFiles();
    });
  }

  createEfileGSTokenRequest() {
    this.selectedlocator = this.uploadedEfiles.map((item) => item.locator);
    if (!this.selectedlocator) {
      this.notifierService.notifyByType('Tax Return not found.', 'error', 10);
      return;
    }

    this.trAuthService.getGSAccessToken().subscribe((response) => {
      if (!response.error) {
        if (response.accessToken && response.accessToken.length > 0) {
          this.saveEfileAttachment();
        } else {
          let goSystemAuthWindow = window.open(
            response.authUrl,
            'GoSystemAuthenticationWindow',
            'width=300,height=300,top=300'
          );
          let popupTick = setInterval(() => {
            if (goSystemAuthWindow.closed) {
              clearInterval(popupTick);
              this.saveEfileAttachment();
            }
          }, 500);
        }
      } else {
        this.notifierService.notifyByType(response.message, 'error');
      }
    });
  }
}
