import { Injectable, Injector } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { ImportDto } from '../models/import.model';

@Injectable({
  providedIn: 'root'
})
export class ImportExportService extends BaseService {
  public refresh$: Subject<boolean> = new Subject();
  public isLoading = false;
  public readonly importExportServiceUrl = this.appSettings.baseApiUrl.concat('/api/importExport/');

  constructor(private injector: Injector) {
    super(injector);
  }

  public refresh(): void {
    this.refresh$.next(true);
  }

  public uploadEntityMappings(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file.rawFile, file.name);
    return this.http.post(`${this.importExportServiceUrl}UploadEntityMappings`, formData);
  }

  public importData(file: any, importDto: ImportDto) {
    const formData = new FormData();
    formData.append('file', file.rawFile, file.name);
    formData.append('data', JSON.stringify(importDto));

    return this.http.post<any>(`${this.importExportServiceUrl}ImportData`, formData);
  }

  public exportPrintSettings(): Observable<any> {
    return this.http.get(`${this.importExportServiceUrl}ExportPrintSettings`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public exportEntities(): Observable<any> {
    return this.http.get(`${this.importExportServiceUrl}ExportEntities`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public exportTableData(tableName: string): Observable<any> {
    const params = new HttpParams().set('tableName', tableName);
    return this.http.get(`${this.importExportServiceUrl}ExportTableData`, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  public getImportData(tableName: string, tramImportId: number): Observable<any> {
    let params = new HttpParams().set('tableName', tableName).set('tramImportId', tramImportId);
    return this.http.get<any>(`${this.importExportServiceUrl}GetImportData`, { params });
  }

  public exportEntitiesListing(taskId: number): Observable<any> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get(`${this.importExportServiceUrl}ExportEntityListing`, {
      params,
      responseType: 'blob',
      observe: 'response'
    });
  }

  public exportEntitiesSetting(taskId: number): Observable<any> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get(`${this.importExportServiceUrl}ExportEntitySettings`, {
      params,
      responseType: 'blob',
      observe: 'response'
    });
  }
}
