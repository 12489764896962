<div class="user-profile" kendoPopoverAnchor [popover]="profilePopover" [ngClass]="{ opened: isOpen }">
  <div class="profile-avatar">
    <span>{{ profile?.firstName.charAt(0) }}</span>
  </div>
  <span id="auth-profile" class="d-none d-xl-inline ml-1">
    hi,
    <span class="username">{{ profile?.firstName }}</span>
  </span>
  <span class="k-icon k-i-arrow-chevron-down ml-1 d-none d-xl-inline"></span>
</div>
<kendo-popover
  #profilePopover
  position="bottom"
  class="profile-popover"
  [callout]="true"
  [width]="250"
  (show)="toggle(true)"
  (hide)="toggle(false)">
  <ng-template kendoPopoverBodyTemplate>
    <ul class="profile-nav">
      <li class="logout">
        <span (click)="onLogout()" class="btn btn-link">Logout</span>
      </li>
    </ul>
  </ng-template>
</kendo-popover>
