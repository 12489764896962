import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileUtils {
  public static exportData(response: any, fileName: string) {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader && contentDispositionHeader.indexOf('attachment') !== -1) {
      const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = regex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    saveAs(response.body, fileName);
  }
}
