import { Component, Injector, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { CookieConsentService } from './core/services/cookie-consent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private router: Router;
  public title = 'Angular Template';
  public isPageLoading = false;
  public defaultLayout: boolean = true;

  constructor(
    injector: Injector,
    private cookieConsentService: CookieConsentService
  ) {
    this.router = injector.get(Router);
  }

  public ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.isPageLoading = true;
      }
      if (e instanceof NavigationEnd) {
        if (this.isPageLoading) {
          this.isPageLoading = false;
        }
      }
      if (this.router.url.includes('/taxreturn/preview')) {
        this.defaultLayout = false;
      } else {
        this.defaultLayout = true;
      }
    });
    this.cookieConsentService.init();
  }
}
