import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  public readonly adminServiceUrl = this.appSettings.baseApiUrl.concat('/api/admin/');
  //public adminServicesRefresh$: Subject<boolean> = new Subject();

  constructor(private injector: Injector) {
    super(injector);
  }
  public getGSTokenGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}GetGSTokenGrid`);
  }
  public deleteGSToken(userId: number): Observable<any> {
    const requestBody = {
      userId: userId
    };
    return this.http.post<string>(`${this.adminServiceUrl}DeleteGSToken`, requestBody);
  }
  public getServicesGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}GetServicesGrid`);
  }

  public updateService(ids: number[], comments: string, isActivation: boolean): Observable<any> {
    const requestBody = {
      ids: ids,
      comments: comments
    };
    const params = new HttpParams().set('isActivation', isActivation);
    return this.http.post<string>(`${this.adminServiceUrl}UpdateService`, requestBody, { params });
  }
  // public refresh() {
  //   this.adminServicesRefresh$.next(true);
  // }

  public getSettingsGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}GetSettingsGrid`);
  }
  public updateSetting(settingName: string, settingValue: string): Observable<any> {
    const params = new HttpParams().set('settingName', settingName).set('settingValue', settingValue);
    return this.http.post<string>(`${this.adminServiceUrl}UpdateSetting`, null, { params });
  }

  public getGlobalTaskGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}GetGlobalTasksGrid`);
  }

  public getGlobalEntityListingGrid(taskId: number, workAreaId: number, is7216Consent: boolean): Observable<any> {
    const params = new HttpParams()
      .set('taskId', taskId)
      .set('workAreaId', workAreaId)
      .set('Is7216Consent', is7216Consent);
    return this.http.get(`${this.adminServiceUrl}GetGlobalEntityListing`, { params });
  }

  public getGlobalEntitySettingGrid(taskId: number): Observable<any> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get(`${this.adminServiceUrl}GetGlobalEntitySettings`, { params });
  }

  public getClientProperties(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}ClientProperties`);
  }

  public deleteClientProperties(clientId: number): Observable<any> {
    const params = new HttpParams().set('clientId', clientId);
    return this.http.post<any>(`${this.adminServiceUrl}DeleteClientProperties`, null, { params });
  }

  public getPublishGroups(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}PublishGroups`);
  }

  public deletePublishGroup(publishGroupTag: string): Observable<any> {
    const params = new HttpParams().set('publishGroupTag', publishGroupTag);
    return this.http.post<any>(`${this.adminServiceUrl}DeletePublishGroup`, null, { params });
  }

  public getEOrgRetrieveTaskDetails(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}EOrgRetrieveTaskDetails`);
  }

  public createEOrgRetrievalTask(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}CreateEOrgRetrievalTask`);
  }

  public getPredefinedEfileDescriptions(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}EfileDescriptions`);
  }

  public getModulesGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}ModulesGrid`);
  }

  public getProfiles(): Observable<any> {
    return this.http.get<any>(`${this.adminServiceUrl}GetProfiles`);
  }

  public getAllTRAMModules(): Observable<any> {
    return this.http.get<any>(`${this.adminServiceUrl}GetAllTRAMModules`);
  }

  public getAllProfiles(): Observable<any> {
    return this.http.get<any>(`${this.adminServiceUrl}GetAllProfiles`);
  }

  public getAllIndividualClients(): Observable<any> {
    return this.http.get<any>(`${this.adminServiceUrl}GetAllIndividualClients`);
  }

  public addWorkAreaClientProfile(workAreaId: number, clientId: number, profileId: number): Observable<any> {
    const params = new HttpParams().set('workAreaId', workAreaId).set('clientId', clientId).set('profileId', profileId);
    return this.http.post<any>(`${this.adminServiceUrl}AddWorkAreaClientProfile`, null, { params });
  }

  public getTaskTypesGrid(): Observable<any> {
    return this.http.get(`${this.adminServiceUrl}GetTaskTypesGrid`);
  }
  public updateTaskTypeStatus(ids: number[], isActivation: boolean): Observable<any> {
    const requestBody = {
      ids: ids
    };
    const params = new HttpParams().set('isActivation', isActivation);
    return this.http.post<string>(`${this.adminServiceUrl}UpdateTaskTypeStatus`, requestBody, { params });
  }

  public approveCalc(tasks: any, comments: string) {
    const requestBody = {
      tasks,
      comments: comments
    };
    return this.http.post<any>(`${this.adminServiceUrl}ApproveCalc`, requestBody);
  }
}
