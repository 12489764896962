import { Component, Input, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss']
})
export class GenericGridComponent implements OnInit {
  @Input() gridData: any;
  @Input() isGridLoading: boolean = true;
  @Input() isExport: boolean = false;
  @Input() title: string = '';
  public kendoGridProperties = kendoGridProperties;
  constructor() {}

  ngOnInit() {
    this.allData = this.allData.bind(this);
  }

  public allData(): any {
    const result: ExcelExportData = {
      data: this.gridData.data
    };
    return result;
  }
}
