import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActionsLayout } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() confirmBtnText: string = 'Yes';
  @Input() cancelBtnText: string = 'No';
  @Output() yesClick = new EventEmitter<void>();
  @Output() noClick = new EventEmitter<void>();
  @Output() closeClick = new EventEmitter<void>();
  public actionsLayout: ActionsLayout = 'end';
  public opened: boolean = false;

  public onYes() {
    this.yesClick.emit();
  }
  public onNo(): void {
    this.noClick.emit();
  }
  public close(): void {
    this.closeClick.emit();
  }
}
