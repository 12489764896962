<div class="toolbar">
  <!-- <button *ngIf="showBackBtn" (click)="goBack()" class="btn btn-back btn-icon mr-3">
    <i class="far fa-arrow-left" aria-hidden="true"></i>
  </button> -->

  <!-- display title or title template -->
  <ng-container *ngIf="titleTemplate; else regularTitle"> </ng-container>
  <ng-template #regularTitle>
    <h1 *ngIf="title" class="page-title">{{ title }}</h1>
  </ng-template>

  <!-- Display subtitle or subtitle template -->
  <ng-container *ngIf="subTitleTemplate; else regularSubTitle">
    <ng-container *ngTemplateOutlet="subTitleTemplate"></ng-container>
  </ng-container>
  <ng-template #regularSubTitle>
    <span *ngIf="subTitle" class="page-subtitle">{{ subTitle }}</span>
  </ng-template>

  <div class="action-toolbar">
    <ng-content></ng-content>
  </div>
</div>
