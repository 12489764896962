import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

import { AdminService } from 'src/app/core/services/admin.service';
import { NotifierService } from 'src/app/core/services/notifier.service';

import { SettingsData } from 'src/app/core/models/settings.model';
import { kendoGridProperties } from 'src/utils/tramConstants';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent extends DialogContentBase implements OnInit {
  public appSettingsData: SettingsData[];
  public kendoGridProperties = kendoGridProperties;
  public isLoading: boolean = false;
  public opened: boolean = true;
  close: any;

  constructor(
    private adminService: AdminService,
    private notifierService: NotifierService,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit() {
    this.getSettingsGrid();
  }

  public getSettingsGrid(): void {
    this.isLoading = true;
    this.adminService
      .getSettingsGrid()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((resp) => {
        this.appSettingsData = resp;
      });
  }

  updateAppSettingsValue(dataItem: SettingsData) {
    if (!dataItem.newValue) {
      this.notifierService.notifyByType('Error. Please enter a Setting Value to update.', 'error');
      dataItem.newValue = '';
      return;
    }
    if (dataItem.newValue === dataItem.value) {
      this.notifierService.notifyByType('Error. The new Setting value exactly matches the existing value.', 'error');
      dataItem.newValue = '';
      return;
    }
    this.adminService.updateSetting(dataItem.name, dataItem.newValue).subscribe(() => {
      dataItem.newValue = '';
      this.getSettingsGrid();
      this.notifierService.notifyByType('Success. The selected setting is updated.', 'success');
    });
  }
}
