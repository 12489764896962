import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { NotifierService } from 'src/app/core/services/notifier.service';
import { TRAuthService } from 'src/app/core/services/tr-auth.service';

@Component({
  selector: 'app-view-taxreturn',
  templateUrl: './view-taxreturn.component.html',
  styleUrls: ['./view-taxreturn.component.scss']
})
export class ViewTaxreturnComponent implements OnInit {
  public gsAccount: string;
  public authCode: string = null;
  public viewTaxReturn: boolean = false;
  public taxReturnURL: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private trAuthService: TRAuthService,
    private notifierService: NotifierService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.authCode = params['code'];
    });
    if (this.authCode != null) {
      this.trAuthService.getAccessTokenByAuthCode(this.authCode).subscribe((resp) => {
        if (resp.error == true) {
          this.notifierService.notifyByType(resp.message, 'error');
        } else {
          if (window.name == 'GoSystemAuthenticationWindow') {
            window.close();
          } else {
            window.history.replaceState({}, document.title, '/taxreturn/preview');
            this.openTaxReturn();
          }
        }
      });
    } else {
      this.trAuthService.getGSAccessToken().subscribe((resp) => {
        if (resp.error == true) {
          this.notifierService.notifyByType(resp.Message, 'error');
        } else if (resp.accessToken != null && resp.accessToken.length > 0) {
          this.openTaxReturn();
        } else {
          window.location.href = resp.authUrl;
        }
      });
    }
  }

  public openTaxReturn() {
    this.gsAccount = JSON.parse(atob(localStorage.getItem('gsAccount')));
    let taxReturn = JSON.parse(atob(localStorage.getItem('taxReturn')));
    this.trAuthService.getTaxReturnUrl(taxReturn, this.gsAccount).subscribe((resp) => {
      if (resp.error == true) {
        this.notifierService.notifyByType(resp.Message, 'error');
      } else {
        this.viewTaxReturn = true;
        this.taxReturnURL = this.sanitizer.bypassSecurityTrustResourceUrl(resp.loadURL);
      }
    });
  }
}
