import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-client-selection-modal',
  templateUrl: './client-selection-modal.component.html',
  styleUrls: ['./client-selection-modal.component.scss']
})
export class ClientSelectionModalComponent implements OnInit {
  public clientIdSelected: number;
  @Output() close = new EventEmitter<boolean>();
  @Input() clientSelected: number;

  ngOnInit() {
    this.clientIdSelected = this.clientSelected;
  }

  closeModal(): void {
    this.close.emit(true);
  }
}
