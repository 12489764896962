import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { Form, FormType, Profile } from '../models/form-data.model';

import { BaseService } from './base.service';
import { AuditField, EOrgField, Field, FieldDelete, GSField } from '../models/fields.models';
import { StringUtils } from 'src/utils/stringUtils';
import { GridFilters } from '../models/utils.models';

@Injectable({
  providedIn: 'root'
})
export class FieldService extends BaseService {
  public isLoading = false;

  public readonly fieldServiceUrl = this.appSettings.baseApiUrl.concat('/api/field/');

  constructor(private injector: Injector) {
    super(injector);
  }

  public getEOrgFieldValues(tramIds?: number[]): Observable<any> {
    const requestBody = {
      ids: tramIds
    };
    return this.http.post<any>(`${this.fieldServiceUrl}GetEOrgFieldValues`, requestBody);
  }

  public getCalcForms(taskId: number): Observable<Form[]> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get<Form[]>(`${this.fieldServiceUrl}GetCalcForms`, { params });
  }

  public getCalcTaskDetails(taskId: number): Observable<any> {
    const params = new HttpParams().set('taskId', taskId);
    return this.http.get<any>(`${this.fieldServiceUrl}GetCalcFieldsCount`, { params });
  }

  public getCommonCalcForms(tramIds?: number[]): Observable<any> {
    const requestBody = {
      ids: tramIds
    };
    return this.http.post<any>(`${this.fieldServiceUrl}GetCommonCalcForms`, requestBody);
  }

  public getGSFieldValueForms(): Observable<any> {
    return this.http.post(`${this.fieldServiceUrl}GSFieldValueForms`, null);
  }

  public getTaxYearFormsDropdown(taxYear: number): Observable<any> {
    const params = new HttpParams().set('taxYear', taxYear);
    return this.http.get<any>(`${this.fieldServiceUrl}getTaxYearFormsDropdown`, { params });
  }

  public getCalcFieldsDropdown(formId: number, taxYear: number): Observable<any> {
    const params = new HttpParams().set('formId', formId).set('taxYear', taxYear);
    return this.http.get<any>(`${this.fieldServiceUrl}GetCalcFieldsDropdown`, { params });
  }

  public getCalcReportGrid(formId: number, fieldNames: string[], taxYear: number): Observable<any> {
    const requestBody = {
      formId: formId,
      fieldNames: fieldNames,
      taxYear: taxYear
    };
    return this.http.post<string>(`${this.fieldServiceUrl}GetCalcReportGrid`, requestBody);
  }

  public getForms(formTypeId: number = 0): Observable<Form[]> {
    const params = new HttpParams().set('formTypeId', formTypeId);
    return this.http.get<Form[]>(`${this.fieldServiceUrl}GetForms`, { params });
  }

  public getExistingForms(): Observable<any> {
    return this.http.get<any>(`${this.fieldServiceUrl}GetUnmappedForms`);
  }

  public addExistingForm(formId: number): Observable<any> {
    const params = new HttpParams().set('formId', formId);
    return this.http.post<any>(`${this.fieldServiceUrl}AddExistingForm`, null, { params });
  }

  public addForms(formName: string, displayName: string, formTypeId: number, isTestForm: boolean): Observable<any> {
    const params = new HttpParams()
      .set('formName', formName)
      .set('displayName', displayName)
      .set('formTypeId', formTypeId)
      .set('isTestForm', isTestForm);
    return this.http.post<any>(`${this.fieldServiceUrl}AddForms`, null, { params });
  }

  public getFormType(): Observable<FormType[]> {
    return this.http.get<FormType[]>(`${this.fieldServiceUrl}GetFormType`);
  }

  public getFormFieldType(): Observable<FormType[]> {
    return this.http.get<FormType[]>(`${this.fieldServiceUrl}GetFormFieldType`);
  }

  public getProfiles(): Observable<Profile[]> {
    return this.http.get<Profile[]>(`${this.fieldServiceUrl}GetProfileValues`);
  }

  public getMappings(
    api: string,
    formId: number,
    profileId: number,
    pageSize: number = 0,
    skip: number = 0,
    filters: GridFilters[],
    sort: any
  ) {
    const requestBody = {
      formId: formId,
      profileId: profileId,
      filters: filters,
      page: { pageSize: pageSize, skip: skip },
      sort: sort
    };

    return this.http.post<any>(`${this.fieldServiceUrl}${api}`, requestBody);
  }

  public exportMappings(api: string, formId: number, profileId: number = 0): any {
    let params = new HttpParams().set('formId', formId);
    if (profileId > 0) {
      params = params.append('profileId', profileId);
    }
    return this.http.get(`${this.fieldServiceUrl}${api}`, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  public updateField(field: Field): Observable<any> {
    field.lookFor = StringUtils.encodeStringExpression(field.lookFor);
    field.macro = StringUtils.encodeStringExpression(field.macro);
    field.condition = StringUtils.encodeStringExpression(field.condition);
    return this.http.post<any>(`${this.fieldServiceUrl}UpdateField`, field);
  }
  public updateAuditField(field: AuditField): Observable<any> {
    field.macro = StringUtils.encodeStringExpression(field.macro);
    field.dataType.toLowerCase();
    return this.http.post<any>(`${this.fieldServiceUrl}UpdateAuditField`, field);
  }

  public updateGSField(field: GSField, resetToStandard: boolean = false): Observable<any> {
    field.lookFor = StringUtils.encodeStringExpression(field.lookFor);
    field.macro = StringUtils.encodeStringExpression(field.macro);
    field.export = StringUtils.encodeStringExpression(field.export);
    field.zeroValueMapping = StringUtils.encodeStringExpression(field.zeroValueMapping);
    const params = new HttpParams().set('resetToStandard', resetToStandard);
    return this.http.post<any>(`${this.fieldServiceUrl}UpdateGSField`, field, { params });
  }

  public updateGSFieldValue(field: EOrgField): Observable<any> {
    field.dataType.toLowerCase();
    return this.http.post<any>(`${this.fieldServiceUrl}UpdateGSFieldValue`, field);
  }

  public deleteField(field: FieldDelete): Observable<any> {
    return this.http.post<any>(`${this.fieldServiceUrl}DeleteField`, field);
  }

  public validateFields(api: string, formId: number, profileId: number = 0) {
    let params = new HttpParams().set('formId', formId);
    if (profileId > 0) {
      params = params.append('profileId', profileId);
    }
    return this.http.get<any>(`${this.fieldServiceUrl}${api}`, { params });
  }

  public importFields(api: string, file: any, formId: number, profileId: number = 0) {
    const formData = new FormData();
    formData.append('file', file.rawFile, file.name);
    let params = new HttpParams().set('formId', formId);
    if (profileId > 0) {
      params = params.append('profileId', profileId);
    }
    return this.http.post<any>(`${this.fieldServiceUrl}${api}`, formData, { params });
  }

  public exportVerisonData(versionId: number, resourceName: string) {
    const params = new HttpParams().set('versionId', versionId).set('resourceName', resourceName);
    return this.http.get(`${this.fieldServiceUrl}ExportVersion`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  public exportFieldVerison(
    formId: number,
    fieldType: string,
    fieldName: string,
    formName: string,
    profileId: number = 0
  ) {
    let requestBody = {
      formId: formId,
      fieldType: fieldType,
      fieldName: fieldName,
      formName: formName,
      profileId: profileId
    };
    return this.http.post(`${this.fieldServiceUrl}ExportFieldVersion`, requestBody, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
