import { Component } from '@angular/core';
import { NavigationItem } from 'src/app/core/models/navigation.model';

import { LayoutService } from 'src/app/core/services/layout-service';
import { ApplicationSettingsComponent } from 'src/app/modules/admin/application-settings/application-settings.component';
import { MicroServicesComponent } from 'src/app/modules/admin/micro-services/micro-services.component';
import { EfileImportComponent } from 'src/app/modules/efile/efile-import/efile-import.component';
import { UserTypEnum } from 'src/utils/tramConstants';

@Component({
  selector: 'app-work-area-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss']
})
export class ClientLayoutComponent {
  public isSidebarCollapsed = false;
  public clientNavItems: NavigationItem[] = [];

  constructor(private layoutService: LayoutService) {
    this.layoutService.sidebarStage$.subscribe((x: boolean) => (this.isSidebarCollapsed = x));
    this.createNavigationGroup();
  }

  private createNavigationGroup(): void {
    this.clientNavItems = [
      {
        text: 'Entity Dashboard',
        url: '/work-area/dashboard',
        icon: 'fa fa-home',
        description: 'Home',
        items: []
      },
      {
        text: 'Libraries',
        url: '/work-area/dataset/libraries',
        icon: 'fa fa-upload',
        description: 'Libraries',
        items: [],
        blockedUserTypes: [UserTypEnum.btsUser, UserTypEnum.psUser]
      },
      {
        text: 'Reports',
        url: '',
        icon: 'fa fa-file',
        description: 'Reports',
        items: [
          {
            text: 'Cross-Entity',
            url: 'reports/cross-entity',
            icon: '',
            description: 'Cross-Entity',
            items: []
          },
          {
            text: 'Field Value',
            url: 'reports/field-value',
            icon: '',
            description: 'Field Value',
            items: []
          },
          {
            text: 'Report Templates',
            url: 'reports/report-templates',
            icon: '',
            description: 'Report Templates',
            items: []
          }
        ]
      },
      {
        text: 'E-File',
        url: '',
        icon: 'fa fa-paperclip',
        description: 'E-File',
        items: [
          {
            text: 'Import',
            url: '',
            icon: '',
            description: 'E-File Import',
            items: [],
            enableAction: true,
            action: {
              componentName: EfileImportComponent
            }
          },
          {
            text: 'Upload History',
            url: 'efile/upload-history',
            icon: '',
            description: 'Upload History',
            items: []
          }
        ]
      },
      {
        text: 'Settings',
        url: '',
        icon: 'fa fa-gear',
        description: 'Settings',
        items: [
          {
            text: 'Client',
            url: 'settings/client',
            icon: '',
            description: 'Client',
            items: []
          },
          {
            text: 'Entity',
            url: 'settings/entity',
            icon: '',
            description: 'Entity',
            items: []
          },
          {
            text: 'Logs',
            url: 'logs/audit-logs',
            icon: '',
            description: 'Logs',
            items: []
          },
          {
            text: 'Sensitive Data',
            url: 'settings/sensitive-data',
            icon: '',
            description: 'Sensitive Data',
            items: []
          },
          {
            text: 'Task Dashboard',
            url: 'admin/task-dashboard',
            icon: '',
            description: 'Task Dashboard',
            items: []
          },
          {
            text: 'Users',
            url: 'admin/users',
            icon: '',
            description: 'Users',
            items: []
          }
        ]
      },
      {
        text: 'Developer',
        url: '',
        icon: 'fa fa-code',
        description: 'Developer',
        items: [
          {
            text: 'Application Settings',
            url: '',
            icon: '',
            description: 'Application Settings',
            items: [],
            enableAction: true,
            action: {
              componentName: ApplicationSettingsComponent
            }
          },
          {
            text: 'Dev Dashboard',
            url: 'developer/dev-dashboard',
            icon: '',
            description: 'Dashboard',
            items: []
          },
          {
            text: 'Micro Services',
            url: '',
            icon: '',
            description: 'Micro Services',
            items: [],
            enableAction: true,
            action: {
              componentName: MicroServicesComponent
            }
          },
          {
            text: 'Process Logs',
            url: 'logs/process-logs',
            icon: '',
            description: 'Dashboard',
            items: []
          },
          {
            text: 'GoSystem Token',
            url: 'admin/gs-token',
            icon: '',
            description: 'Dashboard',
            items: []
          },
          {
            text: 'Validate Users',
            url: 'developers/validate-users',
            icon: '',
            description: 'Dashboard',
            items: []
          }
        ]
      },
      {
        text: 'Privacy and Legal',
        url: 'work-area/dashboard',
        icon: 'fa fa-balance-scale',
        description: 'Privacy and Legal',
        items: [
          {
            text: 'Copyright © 2024',
            url: '#',
            icon: '',
            description: 'copyright',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'Version 2.0',
            url: '#',
            icon: '',
            description: 'version',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'Privacy Policy',
            url: 'https://www2.deloitte.com/us/en/footerlinks1/tax-privacy.html?cq_ck=1526676379074',
            icon: 'fa fa-external-link',
            description: 'Privacy Policy',
            items: [],
            styleClass: 'footer-ext-link'
          },
          {
            text: 'Terms of Use',
            url: 'https://www2.deloitte.com/us/en/legal/terms-of-use.html',
            icon: 'fa fa-external-link',
            description: 'Terms of Use',
            items: [],
            styleClass: 'footer-ext-link'
          },
          {
            text: 'Cookies',
            url: 'https://cookienotice.deloitte.com/',
            icon: 'fa fa-external-link',
            description: 'Cookies',
            items: [],
            styleClass: 'footer-ext-link1'
          },
          {
            text: 'Deloitte Development LLC',
            url: '#',
            icon: '',
            description: 'Deloitte Development LLC',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'All rights reserved',
            url: '#',
            icon: '',
            description: 'Rights',
            items: [],
            styleClass: 'non-selectable'
          }
        ],
        styleClass: 'side-nav-footer'
      }
    ];
  }
}
