/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringUtils {
  public static encodeStringExpression(string: string) {
    let stringEncoders: { [key: string]: string } = {
      '+': '#expAdd$',
      '*': '#expMul$',
      '-': '#expSub$',
      '/': '#expDiv$',
      '<': '#expOab$',
      '>': '#expCab$',
      '=': '#expEq$',
      '!': '#expInv$',
      '[': '#expOsb$',
      ']': '#expCsb$',
      ';': '#expSCol$'
    };
    for (const op in stringEncoders) {
      string = string.replaceAll(op, stringEncoders[op]);
    }
    return string;
  }
}
