import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { DataSet } from '../models/data-set.model';
import { AssetTypeEnum, DataSetTypeEnum } from 'src/utils/tramConstants';

@Injectable({
  providedIn: 'root'
})
export class DataSetService extends BaseService {
  public readonly dataSetUrl = this.appSettings.baseApiUrl.concat('/api/dataset/');

  constructor(private injector: Injector) {
    super(injector);
  }

  public getWorkAreaDataSources(): Observable<DataSet[]> {
    return this.http.get<DataSet[]>(`${this.dataSetUrl}WorkAreaDataSources`);
  }

  public getMasterLibraryDropdown(): Observable<DataSet[]> {
    return this.http.get<DataSet[]>(`${this.dataSetUrl}MasterLibraryDropdown`);
  }

  public getLibraryData(dataSetName: string, dataSetType: DataSetTypeEnum): Observable<any> {
    const params = new HttpParams().set('dataSetName', dataSetName).set('dataType', dataSetType);
    return this.http.get<any>(`${this.dataSetUrl}GetLibraryData`, { params });
  }

  public getDataSetData(dataSetName: string, dataSetType: DataSetTypeEnum): Observable<any> {
    const params = new HttpParams().set('dataSetName', dataSetName).set('dataType', dataSetType);
    return this.http.get<any>(`${this.dataSetUrl}GetDataSetData`, { params });
  }

  public getDataSourceCalc(): Observable<any> {
    return this.http.get(`${this.dataSetUrl}DataSourceCalc`);
  }

  public getActiveDataSources(tramId: number): Observable<any> {
    const params = new HttpParams().set('tramId', tramId);
    return this.http.get<any>(`${this.dataSetUrl}GetActiveEntityDataSources`, { params });
  }

  public exportDataSet(dataSetName: string, entityCodeList?: string[]): any {
    const requestBody = {
      dataSetName: dataSetName,
      entityCodes: entityCodeList
    };
    return this.http.post(`${this.dataSetUrl}ExportDataSet`, requestBody, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public getAssetConfig(assetId: number, assetType: AssetTypeEnum) {
    const params = new HttpParams().set('assetId', assetId).set('assetType', assetType);
    return this.http.get<any>(`${this.dataSetUrl}GetAssetConfig`, { params });
  }

  public assetConfigUpdate(assetId: number, assetType: AssetTypeEnum, isLocking: boolean): Observable<any> {
    const requestBody = {
      assetId: assetId,
      assetTypeId: assetType,
      isLocked: isLocking
    };
    return this.http.post<string>(`${this.dataSetUrl}AssetConfigUpdate`, requestBody);
  }

  public getMissingMasterLineLibrary(): Observable<any> {
    return this.http.get<any>(`${this.dataSetUrl}GetMissingMLL`);
  }

  public backupResourceData(resourceName: string): Observable<any> {
    const params = new HttpParams().set('resourceName', resourceName);
    return this.http.get<any>(`${this.dataSetUrl}AddResourceVersion`, { params });
  }

  public getResourceVersion(resourceName: string): Observable<any> {
    const params = new HttpParams().set('resourceName', resourceName);
    return this.http.get<any>(`${this.dataSetUrl}GetResourceVersion`, { params });
  }

  public exportVerisonData(versionId: number, resourceName: string, displayName: string) {
    const params = new HttpParams()
      .set('versionId', versionId)
      .set('resourceName', resourceName)
      .set('displayName', displayName);
    return this.http.get(`${this.dataSetUrl}ExportVersionData`, {
      params: params,
      observe: 'response',
      responseType: 'blob'
    });
  }

  public getOverrideData(tableName: string): Observable<any> {
    const params = new HttpParams().set('tableName', tableName);
    return this.http.get<any>(`${this.dataSetUrl}GetClientOverrides`, { params });
  }

  public getColumnValidations(): Observable<any> {
    return this.http.get<any>(`${this.dataSetUrl}GetColumnValidations`);
  }

  public getDataSourceDetails(): Observable<any> {
    return this.http.get<any>(`${this.dataSetUrl}GetDataSetDetails`);
  }

  public getDataSourceDropdown(): Observable<any[]> {
    return this.http.get<any[]>(`${this.dataSetUrl}GetUnMappedDataSets`);
  }

  public updateDataSetWorkArea(dataSetId: number, isEnable: boolean): Observable<any> {
    const params = new HttpParams().set('dataSetId', dataSetId).set('isEnable', isEnable);
    return this.http.post<string>(`${this.dataSetUrl}UpdateDataSetWorkArea`, null, { params });
  }

  public updateDataSetDetails(
    dataSetId: number,
    tableName: string,
    displayName: string,
    importExportColumns: string,
    uiColumns: string,
    freezeColumnCount: number
  ): Observable<any> {
    const requestBody = {
      dataSetId: dataSetId,
      tableName: tableName,
      displayName: displayName,
      importExportColumns: importExportColumns,
      uiColumns: uiColumns,
      freezeColumnCount: freezeColumnCount
    };
    return this.http.post<string>(`${this.dataSetUrl}UpdateDataSetDetails`, requestBody);
  }

  public validateDataSourceColumns(): Observable<any> {
    return this.http.get<any>(`${this.dataSetUrl}ValidateDataSetColumns`);
  }

  public getColumnDisplayNames(): Observable<any> {
    return this.http.get(`${this.dataSetUrl}GetColumnDisplayNames`);
  }

  public updateColumnDisplayName(tableName: string, columnName: string, displayName: string): Observable<any> {
    const requestBody = {
      tableName: tableName,
      columnName: columnName,
      displayName: displayName
    };
    return this.http.post<string>(`${this.dataSetUrl}UpdateColumnDisplayName`, requestBody);
  }
}
