<kendo-dialog (close)="this.closeResourceDialog.emit()" class="dialog-sizing-md">
  <kendo-dialog-titlebar><div class="vertical-center page-header">Import Validation Flags</div></kendo-dialog-titlebar>
  <kendo-grid
    [kendoGridBinding]="resourceVersions"
    class="dialog-grid-sizing"
    [sortable]="true"
    [loading]="isLoading"
    filterable="menu"
    [pageable]="true"
    [pageSize]="kendoGridProperties.pageSize">
    <kendo-grid-column field="versionId" title="Version"></kendo-grid-column>
    <kendo-grid-column title="Actions">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          *ngIf="!isDownloading(dataItem)"
          (click)="isFieldVersion() ? downloadFieldVersionReport(dataItem) : downloadVersionReport(dataItem)"
          class="btn btn-primary">
          Download
        </button>
        <div *ngIf="isDownloading(dataItem)" class="download-spinner">
          <kendo-loader type="converging-spinner" size="small"> </kendo-loader> Downloading...
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ts" title="Timestamp"></kendo-grid-column>
    <kendo-grid-column field="userName" title="User Name"></kendo-grid-column>
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="kendoGridProperties.navigatorCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="kendoGridProperties.pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</kendo-dialog>
