import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private sidebarToggle$ = new BehaviorSubject(true);
  public sidebarStage$ = this.sidebarToggle$.asObservable();
  private sidebarUser$ = new BehaviorSubject(false);
  public sidebarUserStage$ = this.sidebarUser$.asObservable();

  get isSidebarOpened() {
    return this.sidebarToggle$.getValue();
  }

  get isUserSettingsOpened() {
    return this.sidebarUser$.getValue();
  }

  toggleSidebar() {
    this.sidebarToggle$.next(!this.isSidebarOpened);
  }

  toggleUserSettings() {
    this.sidebarUser$.next(!this.isUserSettingsOpened);
  }
}
