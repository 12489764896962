<kendo-dialog title="Switch Client" (close)="closeModal()" [width]="700" maxHeight="90%" [minHeight]="300">
  <div class="container" style="padding-right: 54%">
    <div class="row" style="padding-top: 10%; padding-bottom: 10%">
      <div class="col-sm-12 col-lg-10 col-xl-9 mx-auto">
        <div class="client-selection-modal-section px-2 py-5 px-sm-3 px-md-5">
          <app-client-selection (success)="closeModal()" [clientIdSelected]="clientIdSelected"></app-client-selection>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
