import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { AppSettings } from '../models/app-settings.model';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  public appSettings: AppSettings;
  public http: HttpClient;
  private appSettingsService: AppSettingsService;

  constructor(injector: Injector) {
    this.appSettingsService = injector.get(AppSettingsService);
    this.http = injector.get(HttpClient);
    this.appSettings = this.appSettingsService.getAllSettings();
  }
}
