import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { NotifierService } from 'src/app/core/services/notifier.service';

@Component({
  selector: 'app-multiselect-checkbox-dialog',
  templateUrl: './multiselect-checkbox-dialog.component.html',
  styleUrls: ['./multiselect-checkbox-dialog.component.scss']
})
export class MultiselectCheckboxDialogComponent implements OnInit {
  @Input() public iteratorData: { [key: string | number]: string } | string[];
  @Input() modalTitle: string = '';
  @Input() action: string = '';
  @Input() modalSizeClass: string = 'dialog-sizing-lg';
  @Output() closeClick = new EventEmitter<void>();
  @Output() actionTrigger = new EventEmitter<string[]>();
  public selectionDict: { [key: string | number]: boolean } = {};
  constructor(private notifierService: NotifierService) {}
  ngOnInit(): void {
    if (Array.isArray(this.iteratorData)) {
      let listToDict: { [key: string]: string } = {};
      this.iteratorData.forEach((element) => {
        listToDict[element] = element;
      });
      this.iteratorData = listToDict;
    }
  }

  manageSelection(isSelected: boolean): void {
    Object.keys(this.iteratorData).forEach((key) => {
      this.selectionDict[key] = isSelected;
    });
  }

  triggerAction(): void {
    const selectionList = Object.keys(this.selectionDict).filter((key) => this.selectionDict[key] === true);
    if (selectionList == undefined || selectionList.length < 1) {
      this.notifierService.notifyByType('Please make a selection to proceed.', 'error', 10);
    } else {
      this.actionTrigger.emit(selectionList);
    }
  }

  public close(): void {
    this.closeClick.emit();
  }
}
