import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TRAuthService extends BaseService {
  private readonly taxReturnUrl = this.appSettings.baseApiUrl.concat('/api/trAuth/');

  constructor(private injector: Injector) {
    super(injector);
  }

  public getGSAccessToken(): Observable<any> {
    return this.http.get<any>(`${this.taxReturnUrl}GetGoSystemAccessToken`);
  }

  public getAccessTokenByAuthCode(authCode: string): Observable<any> {
    const params = new HttpParams().set('authCode', authCode);
    return this.http.get<any>(`${this.taxReturnUrl}GetAccessTokenByAuthCode`, { params });
  }

  public getTaxReturnUrl(taxReturn: string, gsAccount: string): Observable<any> {
    const params = new HttpParams().set('account', gsAccount).set('locator', taxReturn);
    return this.http.get<any>(`${this.taxReturnUrl}GetTaxReturnUrl`, { params });
  }
}
