import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GsheaderComponent } from './gsheader/gsheader.component';
import { ViewTaxreturnComponent } from './view-taxreturn/view-taxreturn.component';

@NgModule({
  declarations: [GsheaderComponent, ViewTaxreturnComponent],
  imports: [CommonModule]
})
export class GosystemModule {}
