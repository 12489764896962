import { ChangeDetectorRef, Component } from '@angular/core';
import { LayoutService } from 'src/app/core/services/layout-service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/core/services/client.service';
import { Observable } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { globalClientId, kendoFilterSettings } from 'src/utils/tramConstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public profile: User;
  public isSidebarOpened: boolean;
  public isUserSettingsOpened = false;
  public bellMessage = false;
  public selectedWorkAreaId: number;
  public clientList$: Observable<Client[]>;
  public workareaList$: Observable<any[]>;
  public selectedClientId: number;
  public showClientModal: boolean = false;
  public clientSelected: number;
  public clientId: number;
  public kendoFilterSettings = kendoFilterSettings;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router,
    private clientService: ClientService,
    private cdr: ChangeDetectorRef
  ) {
    this.layoutService.sidebarStage$.subscribe((status) => (this.isSidebarOpened = status));
    this.layoutService.sidebarUserStage$.subscribe((status) => (this.isUserSettingsOpened = status));
    this.authService.profile$.subscribe((user) => {
      if (user) {
        this.profile = { ...user };
      }
    });
    this.authService.userContextDetails$.subscribe((userContext) => {
      if (userContext) {
        this.clientList$ = this.clientService.getclients();
        this.selectedClientId = userContext.clientId;
        this.workareaList$ = this.clientService.getWorkAreas(this.selectedClientId);
        this.selectedWorkAreaId = userContext.workAreaId;

        this.clientId = userContext.clientId;
      } else {
        this.selectedClientId = null;
        this.selectedWorkAreaId = null;
      }
    });
  }

  public toggle(): void {
    this.layoutService.toggleSidebar();
  }

  public toggleUser(): void {
    this.layoutService.toggleUserSettings();
  }

  public logout(): void {
    this.authService.logout().subscribe();
  }

  public hideClientSelection(): boolean {
    const loginPattern = /^\/login($|\?|\?[^/]+|\/$)/;
    if (loginPattern.test(this.router.url)) {
      return true;
    }
    return ['/', '/home', '/error'].includes(this.router.url);
  }

  public clientChange(): void {
    this.showClientModal = true;
    this.clientSelected = this.selectedClientId;
    this.cdr.detectChanges();
    this.selectedClientId = this.clientId;
  }

  public workareaChange(): void {
    if (this.selectedClientId == undefined || !this.selectedWorkAreaId) {
      return;
    }
    this.authService
      .getUserContextId(this.selectedClientId, this.selectedWorkAreaId)
      .subscribe(({ contextId, userTypeId }) => {
        this.authService.saveContextToLocalStorage(
          this.selectedClientId,
          this.selectedWorkAreaId,
          contextId,
          userTypeId
        );
        this.redirectToHome();
      });
  }

  redirectToHome() {
    if (this.selectedClientId == globalClientId) {
      location.href = '/work-area-global/dashboard';
    } else {
      location.href = '/work-area/dashboard';
    }
  }
}
