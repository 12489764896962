<div class="field-list">
  <ul>
    <li>
      <input class="k-textbox k-input k-rounded-md" (input)="onInput($event)" placeholder="Search.." />
    </li>
    <li
      #itemElement
      *ngFor="let item of currentData; let i = index"
      (click)="onSelectionChange(item)"
      [ngClass]="{ 'k-selected': isItemSelected(item) }">
      <div class="form-check">
        <input class="form-check-input" kendoCheckBox type="checkbox" #notification [checked]="isItemSelected(item)" />
        <kendo-label class="form-check-label" [text]="convertToStr(item)"></kendo-label>
      </div>
    </li>
  </ul>
</div>
