import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {
  constructor(private notificationService: NotificationService) {}

  public notifyByType(message: string, type: any, delay: number = 10000) {
    this.notificationService.show({
      content: message,
      animation: { type: 'fade', duration: 10 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: type, icon: true },
      closable: true,
      hideAfter: delay,
      width: 400
    });
  }

  public notify(message: string, isError: boolean, delay: number = 10000) {
    if (isError) {
      this.notifyByType(message, 'error', delay);
    } else {
      this.notifyByType(message, 'success', delay);
    }
  }
}
