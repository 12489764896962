<kendo-dialog (close)="this.closeValidationDialog.emit()" class="dialog-sizing-md">
  <kendo-dialog-titlebar
    ><div class="vertical-center page-header">{{ inputData.header }}</div></kendo-dialog-titlebar
  >
  <kendo-grid
    [kendoGridBinding]="validationData"
    class="dialog-grid-sizing"
    [sortable]="true"
    filterable="menu"
    [pageable]="true"
    [loading]="isLoading"
    [pageSize]="kendoGridProperties.pageSize">
    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-spacer></kendo-grid-spacer>
      <button kendoGridExcelCommand class="btn btn-primary">Export</button>
    </ng-template>
    <kendo-grid-excel [fileName]="inputData.fileName" [fetchData]="gridExcelExport"></kendo-grid-excel>
    <kendo-grid-column
      *ngFor="let column of dataColumns"
      field="{{ column.columnName }}"
      title="{{ column.title }}"
      [width]="250"></kendo-grid-column>
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="kendoGridProperties.navigatorCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="kendoGridProperties.pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</kendo-dialog>
