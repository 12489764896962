import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { EFileAttachments, EFileJurisdiction, EFileType } from '../models/efile.model';

@Injectable({
  providedIn: 'root'
})
export class EFileService extends BaseService {
  public isLoading = false;
  public readonly efileServiceUrl = this.appSettings.baseApiUrl.concat('/api/efile/');

  constructor(private injector: Injector) {
    super(injector);
  }

  public getEfileAttachmentStatus(tramIds?: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post(`${this.efileServiceUrl}GetGSEfileMetadataStatus`, requestBody);
  }

  public getEfileReturnStatus(tramIds?: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post(`${this.efileServiceUrl}EfileReturnStatus`, requestBody);
  }
  public getGSEFileDiagnostics(tramIds?: number[], showcritical: boolean = false): Observable<any> {
    const requestBody = { ids: tramIds };
    const params = new HttpParams().set('showcritical', showcritical);
    return this.http.post(`${this.efileServiceUrl}GetGSEFileDiagnostics`, requestBody, { params });
  }

  public getGSEFileMetrics(tramIds?: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post(`${this.efileServiceUrl}GetGSEFileMetrics`, requestBody);
  }

  public getGSEfileVariance(tramIds?: number[]): Observable<any> {
    const requestBody = { ids: tramIds };
    return this.http.post(`${this.efileServiceUrl}GetGSEfileVariance`, requestBody);
  }

  public getEFileType(): Observable<EFileType[]> {
    return this.http.get<EFileType[]>(`${this.efileServiceUrl}GetEFileType`);
  }

  public getEFileJurisdiction(): Observable<EFileJurisdiction[]> {
    return this.http.get<EFileJurisdiction[]>(`${this.efileServiceUrl}GetEFileJurisdiction`);
  }

  public getUploadedEFiles(): Observable<EFileAttachments[]> {
    return this.http.get<any[]>(`${this.efileServiceUrl}GetUploadedEFiles`);
  }
}
