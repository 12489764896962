import { DOCUMENT } from '@angular/common';
import { Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService extends BaseService {
  private document: Document;
  private renderer: Renderer2;
  constructor(
    injector: Injector,
    private rendererFactory: RendererFactory2
  ) {
    super(injector);
    this.document = injector.get(DOCUMENT);
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public init(): void {
    const script = this.renderer.createElement('script');
    script.src = environment.cookieSrc;
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', environment.cookieDomainScript);
    this.renderer.appendChild(this.document.head, script);
  }
}
