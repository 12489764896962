import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/operators';
import { Client } from 'src/app/core/models/client.model';
import { WorkArea } from 'src/app/core/models/workarea.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ClientService } from 'src/app/core/services/client.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { globalClientId, globalUsers, kendoFilterSettings } from 'src/utils/tramConstants';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit {
  public clientList$: Observable<Client[]>;
  public workareaList$: Observable<WorkArea[]>;
  public selectedClient: Client;
  public selectedWorkarea: WorkArea;
  public kendoFilterSettings = kendoFilterSettings;
  public defaultClient = { clientId: null, clientName: 'Select Client' };
  public defaultWorkArea = { workAreaId: null, workAreaName: 'Select Work Area' };
  public isProcessing: boolean = false;
  @Input() clientIdSelected: number = null;
  @Output() success = new EventEmitter<boolean>();

  constructor(
    private clientService: ClientService,
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService
  ) {
    this.clientList$ = this.clientService.getclients();
  }

  ngOnInit() {
    if (this.clientIdSelected != null) {
      if (!this.selectedClient) {
        this.selectedClient = { clientId: this.clientIdSelected, clientName: '' };
      } else {
        this.selectedClient.clientId = this.clientIdSelected;
      }
      this.workareaList$ = this.clientService.getWorkAreas(this.selectedClient.clientId);
      this.workAreaAutoBinding();
    }
  }

  public clientChange(client: Client): void {
    this.workareaList$ =
      client && client.clientId != null ? this.clientService.getWorkAreas(this.selectedClient.clientId) : EMPTY;
    this.workAreaAutoBinding();
  }

  public submit(): void {
    if (!this.selectedClient || !this.selectedWorkarea) {
      return;
    }

    const { clientId } = this.selectedClient;
    const { workAreaId } = this.selectedWorkarea;
    this.isProcessing = true;

    this.authService
      .getUserContextId(clientId, workAreaId)
      .pipe(finalize(() => (this.isProcessing = false)))
      .subscribe(({ contextId, userTypeId }) => {
        this.authService.saveContextToLocalStorage(clientId, workAreaId, contextId, userTypeId);
        this.clientService.setSelectedClient(this.selectedClient);
        this.clientService.setSelectedWorkArea(this.selectedWorkarea);
        if (clientId == globalClientId) {
          if (globalUsers.indexOf(userTypeId) > -1) {
            location.href = '/work-area-global/dashboard';
          } else {
            this.errorService.setErrorMessage(
              'You are not authorized to access this client. Please contact uspstech@deloitte.com.'
            );
            this.router.navigateByUrl('/error');
          }
        } else {
          location.href = '/work-area/dashboard';
        }
        this.clientIdSelected = null;
        this.success.emit(true);
      });
  }

  workAreaAutoBinding() {
    this.workareaList$.subscribe((data: WorkArea[]) => {
      if (data != undefined && data.length === 1) {
        this.selectedWorkarea = data[0];
        this.submit();
      }
    });
  }
}
