import { Component } from '@angular/core';
import { NavigationItem } from 'src/app/core/models/navigation.model';
import { LayoutService } from 'src/app/core/services/layout-service';
import { UserTypEnum } from 'src/utils/tramConstants';

@Component({
  selector: 'app-global-layout',
  templateUrl: './global-layout.component.html',
  styleUrls: ['./global-layout.component.scss']
})
export class GlobalLayoutComponent {
  public isSidebarCollapsed = false;
  public globalNavItems: NavigationItem[] = [];

  constructor(private layoutService: LayoutService) {
    this.layoutService.sidebarStage$.subscribe((x: boolean) => (this.isSidebarCollapsed = x));
    this.createNavigationGroup();
  }

  private createNavigationGroup(): void {
    this.globalNavItems = [
      {
        text: 'Dashboard',
        url: '/work-area-global/dashboard',
        icon: 'fa fa-list-alt',
        description: 'Dashboard',
        items: [
          {
            text: 'Task',
            url: 'admin/task-dashboard',
            icon: '',
            description: 'Task',
            items: []
          },
          {
            text: 'Query',
            url: 'admin/globaltask',
            icon: '',
            description: 'Query',
            items: []
          },
          {
            text: 'Retrieval',
            url: 'admin/dashboard-retrieval',
            icon: '',
            description: 'Retrieval',
            items: []
          }
        ]
      },
      {
        text: 'Manage',
        url: '',
        icon: 'fa fa-upload',
        description: 'Manage',
        items: [
          {
            text: 'Clients',
            url: 'settings/client',
            icon: '',
            description: 'Clients',
            items: []
          },
          {
            text: 'Import All Fields',
            url: '#',
            icon: '',
            description: 'Import All Fields',
            items: []
          },
          {
            text: 'Mappings',
            url: 'mappings',
            icon: '',
            description: 'Mappings',
            items: [],
            blockedUserTypes: [UserTypEnum.globalLibraryUser]
          },
          {
            text: 'Data Source Calculations',
            url: 'dataset/data-source-calculations',
            icon: '',
            description: 'Data Source Calculations',
            items: []
          },
          {
            text: 'Libraries',
            url: 'dataset/libraries',
            icon: '',
            description: 'Libraries',
            items: []
          },
          {
            text: 'Publish',
            url: 'admin/publish',
            icon: '',
            description: 'Publish',
            items: []
          },
          {
            text: 'Reports',
            url: 'reports/report-templates',
            icon: '',
            description: 'Report Templates',
            items: []
          },
          {
            text: 'Rule Sets',
            url: 'dataset/rule-sets',
            icon: '',
            description: 'Rule Sets',
            items: []
          },
          {
            text: 'Signatures',
            url: '#',
            icon: '',
            description: 'Signatures',
            items: []
          },
          {
            text: 'Users',
            url: 'admin/users',
            icon: '',
            description: 'Users',
            items: []
          }
        ]
      },
      {
        text: 'Admin',
        url: '',
        icon: 'fa fa-gear',
        description: 'Settings',
        items: [
          {
            text: 'Task Type Management',
            url: 'admin/task-type-management',
            icon: '',
            description: 'Active Tasks',
            items: []
          },
          {
            text: 'Data Sources',
            url: 'dataset/manage-datasources',
            icon: '',
            description: 'Data Sources',
            items: [],
            blockedUserTypes: [UserTypEnum.globalUser, UserTypEnum.globalLibraryUser]
          },
          {
            text: 'Display Names',
            url: 'dataset/display-names',
            icon: '',
            description: 'Display Names',
            items: []
          },
          {
            text: 'Forms',
            url: 'admin/forms',
            icon: '',
            description: 'Forms',
            items: []
          },
          {
            text: 'GS Token',
            url: '#',
            icon: '',
            description: 'GS Token',
            items: []
          },
          {
            text: 'Logs',
            url: 'logs',
            icon: '',
            description: 'Logs',
            items: [],
            blockedUserTypes: [UserTypEnum.globalUser, UserTypEnum.globalLibraryUser]
          },
          {
            text: 'Predefined E-File Descriptions',
            url: 'admin/efile-descriptions',
            icon: '',
            description: 'Predefined E-File Descriptions',
            items: []
          },
          {
            text: 'Work Area',
            url: 'admin/work-area-management',
            icon: '',
            description: 'Work Area',
            items: []
          }
        ]
      },
      {
        text: 'Privacy and Legal',
        url: 'work-area/dashboard',
        icon: 'fa fa-balance-scale',
        description: 'Privacy and Legal',
        items: [
          {
            text: 'Copyright © 2024',
            url: '#',
            icon: '',
            description: 'copyright',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'Version 2.0',
            url: '#',
            icon: '',
            description: 'version',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'Privacy Policy',
            url: 'https://www2.deloitte.com/us/en/footerlinks1/tax-privacy.html?cq_ck=1526676379074',
            icon: 'fa fa-external-link',
            description: 'Privacy Policy',
            items: [],
            styleClass: 'footer-ext-link'
          },
          {
            text: 'Terms of Use',
            url: 'https://www2.deloitte.com/us/en/legal/terms-of-use.html',
            icon: 'fa fa-external-link',
            description: 'Terms of Use',
            items: [],
            styleClass: 'footer-ext-link'
          },
          {
            text: 'Cookies',
            url: 'https://cookienotice.deloitte.com/',
            icon: 'fa fa-external-link',
            description: 'Cookies',
            items: [],
            styleClass: 'footer-ext-link1'
          },
          {
            text: 'Deloitte Development LLC',
            url: '#',
            icon: '',
            description: 'Deloitte Development LLC',
            items: [],
            styleClass: 'non-selectable'
          },
          {
            text: 'All rights reserved',
            url: '#',
            icon: '',
            description: 'Rights',
            items: [],
            styleClass: 'non-selectable'
          }
        ],
        styleClass: 'side-nav-footer'
      }
    ];
  }
}
