<kendo-dialog-titlebar>
  <div class="vertical-center page-header">Settings</div>
</kendo-dialog-titlebar>
<kendo-grid
  [kendoGridBinding]="appSettingsData"
  filterable="menu"
  [pageable]="true"
  [resizable]="true"
  [loading]="isLoading"
  [pageSize]="kendoGridProperties.pageSize"
  [selectable]="true"
  class="dialog-grid-sizing">
  <kendo-grid-column field="name" title="Setting Name"></kendo-grid-column>
  <kendo-grid-column field="value" title="Setting Value"> </kendo-grid-column>
  <kendo-grid-column field="newValue" title="New Value">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="d-flex">
        <input class="form-control mr-2" [(ngModel)]="dataItem.newValue" />
        <button class="btn btn-primary" (click)="updateAppSettingsValue(dataItem)">
          <span class="fa fa-edit"></span>
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comments" title="Comments"></kendo-grid-column>

  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="kendoGridProperties.navigatorCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-input></kendo-pager-input>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-page-sizes [pageSizes]="kendoGridProperties.pageSizes"></kendo-pager-page-sizes>
  </ng-template>
</kendo-grid>
