/* eslint-disable @typescript-eslint/naming-convention */
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { User as OidcUser, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';
import { BaseService } from './base.service';
import { HttpHeaders } from '@angular/common/http';
import { UserContextDetails } from '../models/user-context.model';

const TOKEN_KEY = 'access_token';
const USER_PROFILE_KEY = 'profile';
const USER_CONTEXT = 'userContext';
const store = window.localStorage;

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  public readonly getSessionIdUrl = this.appSettings.baseApiUrl.concat('/api/auth/Login');
  public readonly getUserContextIdUrl = this.appSettings.baseApiUrl.concat('/api/auth/SetUserContext');
  public loginCompleted: EventEmitter<User> = new EventEmitter<User>(null);
  private authProfileSubject: BehaviorSubject<User>;
  public profile$: Observable<User>;
  private userManager: UserManager;
  private userContextDetailsSubject: BehaviorSubject<UserContextDetails>;
  public userContextDetails$: Observable<UserContextDetails>;

  constructor(injector: Injector) {
    super(injector);
    this.userManager = new UserManager({
      userStore: new WebStorageStateStore({ store }),
      authority: this.appSettings.azureADAuthority,
      client_id: this.appSettings.azureADClientId,
      redirect_uri: this.appSettings.azureADRedirectUrl,
      post_logout_redirect_uri: this.appSettings.azureADPostLogoutRedirectUri,
      response_type: 'code',
      scope: 'openid email profile',
      silent_redirect_uri: this.appSettings.azureADSilentRedirectUri,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTimeInSeconds: 4,
      filterProtocolClaims: true,
      loadUserInfo: false,
      extraQueryParams: {
        resource: this.appSettings.azureADClientId,
        nonce: 'H5dFp4xxXka4lS3xffGlhg=='
      },
      max_age: this.appSettings.timeout
    });
    this.authProfileSubject = new BehaviorSubject<User>(this.getProfileFromStorage());
    this.profile$ = this.authProfileSubject.asObservable();
    this.userContextDetailsSubject = new BehaviorSubject<UserContextDetails>(this.getContextFromLocalStorage());
    this.userContextDetails$ = this.userContextDetailsSubject.asObservable();
    this.registerSilentRenewTokenEvent();
  }

  public isAuthenticated(): boolean {
    return !this.isTokenExpired();
  }

  private hasToken(): boolean {
    return this.getTokenFromStorage() ? true : false;
  }

  private isTokenExpired(): boolean {
    if (!this.hasToken()) {
      return true;
    }
    const decodedToken = this.decodedJWT(this.getTokenFromStorage());
    return decodedToken.exp < Date.now() / 1000;
  }

  private setTokenToStorage(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getTokenFromStorage(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  private setProfileToStorage(user: User): void {
    localStorage.setItem(USER_PROFILE_KEY, btoa(JSON.stringify(user)));
    this.authProfileSubject.next(user);
  }

  private getProfileFromStorage(): User {
    const userStr = localStorage.getItem(USER_PROFILE_KEY);
    return userStr ? JSON.parse(atob(userStr)) : null;
  }

  public logout(): Observable<any> {
    this.userManager.signoutRedirect();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_PROFILE_KEY);
    return from(this.userManager.clearStaleState());
  }

  public redirectToLogin(): void {
    this.clearSession(); //ToDo check if this fixes the loop of login redirections
    this.userManager.signinRedirect();
  }

  public login(callbackUrl: string): Observable<any> {
    return from(this.userManager.signinRedirectCallback(callbackUrl)).pipe(
      switchMap((user: OidcUser) => this.getSessionId(user)),
      tap(({ user, sessionId }) => {
        const profile = this.mapUserProfile(user, sessionId);
        this.setProfileToStorage(profile);
        this.setTokenToStorage(user.access_token);
        this.loginCompleted.emit(profile);
      })
    );
  }

  public getSessionIdFromLocalStorage(): string {
    const profile = this.getProfileFromStorage();
    if (profile && profile.sessionId) {
      return profile.sessionId;
    }

    return null;
  }

  private getSessionId(user: OidcUser): Observable<{ user: OidcUser; sessionId: string }> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${user.access_token}`
    });
    return this.http.get<any>(this.getSessionIdUrl, { headers }).pipe(
      map((resp) => {
        return { user: user, sessionId: resp.sessionId };
      })
    );
  }

  public getUserContextId(clientId: number, workAreaId: number): Observable<{ contextId: string; userTypeId: number }> {
    const params = new HttpParams().set('clientId', clientId).set('workAreaId', workAreaId);
    return this.http.get<{ contextId: string; userTypeId: number }>(this.getUserContextIdUrl, { params });
  }

  public saveContextToLocalStorage(clientId: number, workAreaId: number, contextId: string, userTypeId: number): void {
    this.clearContext();
    const context = { clientId, workAreaId, contextId, userTypeId };
    localStorage.setItem(USER_CONTEXT, btoa(JSON.stringify(context)));
    this.userContextDetailsSubject.next(context);
  }

  public getContextFromLocalStorage(): { clientId: number; workAreaId: number; contextId: string; userTypeId: number } {
    const context = localStorage.getItem(USER_CONTEXT);
    if (context) {
      return JSON.parse(atob(context));
    }

    return null;
  }

  public clearContext(): void {
    localStorage.removeItem(USER_CONTEXT);
  }

  private mapUserProfile(user: OidcUser, sessionId: string): User {
    const profile = new User();
    profile.firstName = user.profile.given_name;
    profile.lastName = user.profile.family_name;
    profile.sessionId = sessionId;
    return profile;
  }

  public signinSilentrenew(): Observable<any> {
    return from(this.userManager.signinSilentCallback().catch((err) => {}));
  }

  public getFromRepository(key: string): string {
    return localStorage.getItem(key);
  }

  private registerSilentRenewTokenEvent(): void {
    window.addEventListener('silent-renew-message', (event: any) => {
      this.login(event.detail).subscribe(() => console.log('renewed'));
    });
  }

  public clearSession(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_PROFILE_KEY);
    this.userManager.clearStaleState();
    this.userManager.stopSilentRenew();
    this.userManager.removeUser();
  }

  private decodedJWT(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
