<div class="form-group mt-3">
  <label class="mb-1 grid-header">Client:</label>
  <kendo-dropdownlist
    [data]="clientList$ | async"
    [defaultItem]="defaultClient"
    textField="clientName"
    valueField="clientId"
    [(ngModel)]="selectedClient"
    (valueChange)="clientChange($event)"
    [kendoDropDownFilter]="kendoFilterSettings"
    [filterable]="true"
    class="p-dropdownlist">
  </kendo-dropdownlist>
</div>
<div class="form-group mt-4">
  <label class="mb-1 grid-header">Work Area:</label>
  <kendo-dropdownlist
    [data]="workareaList$ | async"
    [defaultItem]="defaultWorkArea"
    [disabled]="!selectedClient"
    textField="workAreaName"
    valueField="workAreaId"
    [(ngModel)]="selectedWorkarea"
    [kendoDropDownFilter]="kendoFilterSettings"
    [filterable]="true"
    class="p-dropdownlist">
  </kendo-dropdownlist>
</div>
<div class="text-center mt-4">
  <button class="btn btn-primary text-center" [disabled]="!selectedClient || !selectedWorkarea" (click)="submit()">
    <span *ngIf="isProcessing">
      <i class="fas fa-circle-notch fa-spin mr-1"></i>
    </span>
    {{ isProcessing ? 'Processing' : 'Continue' }}
    <span *ngIf="!isProcessing">
      <i class="fa fa-arrow-right ml-1"></i>
    </span>
  </button>
</div>
