import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './modules/home/home/home.component';
import { ClientLayoutComponent } from './shared/layout/client-layout/client-layout.component';
import { ErrorComponent } from './shared/layout/error/error.component';
import { ViewTaxreturnComponent } from './modules/gosystem/view-taxreturn/view-taxreturn.component';
import { GlobalLayoutComponent } from './shared/layout/global-layout/global-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'taxreturn/preview',
    component: ViewTaxreturnComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'work-area-global',
    canActivate: [AuthGuard],
    component: GlobalLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/global-dashboard/global-dashboard.module').then((m) => m.GlobalDashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dataset',
        loadChildren: () => import('./modules/dataset/dataset.module').then((m) => m.DataSetModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mappings',
        loadChildren: () => import('./modules/mappings/mappings.module').then((m) => m.MappingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'logs',
        loadChildren: () => import('./modules/logs/logs.module').then((m) => m.LogsModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'work-area',
    canActivate: [AuthGuard],
    component: ClientLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'efile',
        loadChildren: () => import('./modules/efile/efile.module').then((m) => m.EfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'logs',
        loadChildren: () => import('./modules/logs/logs.module').then((m) => m.LogsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dataset',
        loadChildren: () => import('./modules/dataset/dataset.module').then((m) => m.DataSetModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
