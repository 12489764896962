<kendo-dialog-titlebar>Import E-File Attachment</kendo-dialog-titlebar>
<div class="upload-wrapper">
  <kendo-uploaddropzone zoneId="efileAttachmentImport"> Supported file types: PDF </kendo-uploaddropzone>
  <kendo-upload
    zoneId="efileAttachmentImport"
    [saveUrl]="saveUrl"
    [removeUrl]="removeUrl"
    [(ngModel)]="files"
    [restrictions]="fileRestrictions"
    [autoUpload]="true"
    [multiple]="true"
    (select)="disableActionBtns = true"
    (complete)="disableActionBtns = false">
  </kendo-upload>
</div>
<kendo-dialog-actions layout="end">
  <button [disabled]="disableActionBtns" (click)="clearFiles()" class="btn btn-primary mr-2">Clear</button>
  <button [disabled]="disableActionBtns" (click)="getUploadedEFiles()" class="btn btn-primary">Review</button>
</kendo-dialog-actions>

<kendo-dialog *ngIf="reviewUploadedEfileGrid" (close)="closeReviewDialog()" class="dialog-sizing-md">
  <kendo-dialog-titlebar>Review Imported E-File Attachment </kendo-dialog-titlebar>
  <kendo-grid
    [kendoGridBinding]="uploadedEfiles"
    [sortable]="true"
    filterable="menu"
    [pageable]="true"
    [resizable]="true"
    [loading]="isLoading"
    [pageSize]="kendoGridProperties.pageSize"
    [selectable]="true"
    class="dialog-grid-sizing">
    <kendo-grid-column field="entityCode" title="Entity Code"></kendo-grid-column>
    <kendo-grid-column field="jurisdiction" title="Jurisdiction"> </kendo-grid-column>
    <kendo-grid-column field="description" title="Description"> </kendo-grid-column>
    <kendo-grid-column field="fileName" title="File Name"></kendo-grid-column>
    <kendo-grid-column field="locator" title="Locator"> </kendo-grid-column>
    <kendo-grid-column field="comments" title="Status"> </kendo-grid-column>
    <kendo-grid-column title="Remove" [width]="140">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngIf="!dataItem.isActive">
          <button class="btn btn-cancel action-btn" title="Remove Attachment" (click)="removeAttachement(dataItem)">
            <span class="fa fa-times"></span>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-spacer></kendo-grid-spacer>
      <button [disabled]="disableSubmitBtn" (click)="createEfileGSTokenRequest()" class="btn btn-primary mr-2">
        Submit
      </button>
      <button kendoGridExcelCommand class="btn btn-primary">Export</button>
    </ng-template>
    <kendo-grid-excel fileName="E-File Attachment Report.xlsx" [fetchData]="allData"></kendo-grid-excel>

    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="kendoGridProperties.navigatorCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="kendoGridProperties.pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</kendo-dialog>
