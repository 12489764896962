import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gsheader',
  templateUrl: './gsheader.component.html',
  styleUrls: ['./gsheader.component.scss']
})
export class GsheaderComponent {
  public gsFirm: string = 'M309';
  @Input() gsAccount: string;
  refreshPage() {
    window.location.reload();
  }
}
