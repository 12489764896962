import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { MulticheckFilterComponent } from './multicheck-filter/multicheck-filter.component';
import { MultiselectCheckboxDialogComponent } from './multiselect-checkbox-dialog/multiselect-checkbox-dialog.component';
import { KendoModule } from './kendo/kendo.module';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { GenericValidationGridComponent } from './generic-validation-grid/generic-validation-grid.component';
import { GenericGridComponent } from './generic-grid/generic-grid.component';
import { ResourceVersionComponent } from './resource-version/resource-version.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    PromptDialogComponent,
    MulticheckFilterComponent,
    MultiselectCheckboxDialogComponent,
    DateFormatPipe,
    GenericValidationGridComponent,
    GenericGridComponent,
    ResourceVersionComponent,
    ImportDialogComponent
  ],
  imports: [CommonModule, FormsModule, KendoModule],
  exports: [
    ConfirmDialogComponent,
    PromptDialogComponent,
    MulticheckFilterComponent,
    MultiselectCheckboxDialogComponent,
    DateFormatPipe,
    GenericValidationGridComponent,
    GenericGridComponent,
    ResourceVersionComponent,
    ImportDialogComponent
  ],
  providers: [DatePipe]
})
export class SharedModule {}
