import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

export const kendoGridProperties = {
  navigatorCount: 1,
  pageSize: 200,
  pageSizes: [200, 500, 1000],
  calcPageSize: 500,
  calcPageSizes: [500, 1000, 2000]
};

export enum StatusCodeEnum {
  default = 0,
  readyToProcess = 20,
  printing = 21,
  inProcess = 40,
  submitted = 41,
  flowLogs = 80,
  reports = 81,
  paused = 89,
  failed = 90,
  completed = 100,
  cancelled = 101
}

export enum TaskTypeEnum {
  default = 0,
  calculations = 10,
  refreshTramGoSystemSchema = 18,
  getEOrgFieldValue = 20,
  saveEOrgFieldValue = 21,

  // EFile
  efileDiagnostics = 22,
  getEfileStatus = 23,
  eFileMetrics = 24,
  loadEfileAttachments = 25,
  saveEFileAttachment = 26,

  // Print & Publish
  printTaxReturn = 27,
  checkPrintStatus = 28,
  submitPrint = 29,
  publishReports = 30,
  publishTaxReturn = 31,
  printSignature = 32,
  publishBIData = 34,

  //OTT
  saveOttData = 40,
  checkOttSaveStatus = 41,
  crossEntityReport = 50,

  //Reports
  entityReport = 51,

  // IPACS
  calcSavePrint = 60,

  // EFile
  createEfile = 66,
  submitEfile = 67,
  checkEfileStatus = 68,

  // Global Tasks
  getEOrgFieldValuesBatch = 90,
  getClientDataCache = 91
}

export enum ScopeEnum {
  default = 0,
  fixed = 1, //global values and can be overwritable only by Global Client
  variable = 2, //Client values and are overwritable in the Live Client
  entity = 3, //Used for Settings, Setting can be editted for Entity
  private = 5, //Used for internal settings, not to be shown to user
  inactive = 6, //used to make a setting or report inactive
  ignore = 7, //Fields are ignored in the calcs
  suppressed = 10 // Set CalcField values as 0
}

export enum DataSetTypeEnum {
  unknown = 0,
  entityData = 1,
  ruleSet = 2,
  masterData = 3,
  globalMasterData = 4,
  pdfMappings = 5,
  entityEncryptedData = 6
}

export enum AssetTypeEnum {
  unknown = 0,
  dataSet = 1,
  form = 2
}

export enum UserTypEnum {
  btsUser = 5,
  psUser = 20,
  psAdmin = 40,
  globalLibraryUser = 44,
  globalUser = 45,
  devAdmin = 50,
  globalAdmin = 60
}

export const globalUsers = [UserTypEnum.globalAdmin, UserTypEnum.globalLibraryUser, UserTypEnum.globalUser];

export const globalClientId = 0;

export enum ImportEnum {
  merge = 1, //add new data, update existing data
  update = 2, // Update existing data
  override = 3 //delete existing data before adding new data
}
export enum HeaderRow {
  dataSourceHeaderRow = 7,
  genericHeaderRow = 5,
  formHeaderRow = 7,
  clientGenericHeaderRow = 7,
  clientSettingsHeaderRow = 6,
  calculationsHeaderRow = 8,
  fieldVersionsHeaderRow = 7
}
export const kendoFilterSettings: DropDownFilterSettings = {
  caseSensitive: false,
  operator: 'contains'
};

export enum FormTypeEnum {
  federal = 1,
  state = 2,
  gsFields = 11,
  auditFields = 20,
  eorgFields = 21
}

export const fieldTypes: string[] = ['field', 'gosystemfield', 'auditfield', 'eorgfield'];
export const eFileSizeBytes = 60 * 1000 * 1024; //60MB in bytes
