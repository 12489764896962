import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss']
})
export class MulticheckFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public dataDict: { [key: string]: string } = {};
  @Input() public textField;
  @Input() public filterService: FilterService;
  @Input() public field: string;

  public currentData: any[];
  private value: any[] = [];

  public convertToStr = (dataItem: any): string => {
    if (dataItem && dataItem != undefined) {
      return String(dataItem);
    } else {
      return '';
    }
  };
  public ngAfterViewInit(): void {
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public isItemSelected(item: any): boolean {
    return this.value.some((x) => x === item);
  }

  public onSelectionChange(item: any): void {
    if (this.value.some((x) => x === item)) {
      this.value = this.value.filter((x) => x !== item);
    } else {
      this.value.push(item);
    }

    if (this.dataDict != undefined && Object.keys(this.dataDict).length > 0) {
      this.filterService.filter({
        filters: this.value.map((value) => ({
          field: this.dataDict[this.convertToStr(value)],
          operator: 'eq',
          value: true
        })),
        logic: 'or'
      });
    } else {
      this.filterService.filter({
        filters: this.value.map((value) => ({
          field: this.field,
          operator: 'eq',
          value
        })),
        logic: 'or'
      });
    }
  }

  public onInput(e: Event): void {
    this.currentData = distinct(
      [
        ...this.currentData.filter((dataItem) => this.value.some((val) => val === dataItem)),
        ...filterBy(this.data, {
          operator: 'contains',
          field: this.textField,
          value: (e.target as HTMLInputElement).value
        })
      ],
      this.textField
    );
  }
  // TODO : Allow filtering for empty strings
}
