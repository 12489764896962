import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NavigationAction, NavigationItem } from 'src/app/core/models/navigation.model';
import { UserContextDetails } from 'src/app/core/models/user-context.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MicroServicesComponent } from 'src/app/modules/admin/micro-services/micro-services.component';
import { EfileImportComponent } from 'src/app/modules/efile/efile-import/efile-import.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('500ms ease-in', style({ opacity: 0 }))])
    ]),
    trigger('toggleAnimation', [
      state(
        'true',
        style({
          maxHeight: '800px',
          opacity: 1,
          overflow: 'hidden'
        })
      ),
      state(
        'false',
        style({
          height: '0px',
          opacity: 0,
          backgroundColor: '#F3F5F7',
          overflow: 'hidden'
        })
      ),
      transition('true <=> false', [animate('200ms ease-in')])
    ]),
    trigger('toggleArrowAnimation', [
      state(
        'true',
        style({
          transform: 'rotate(180deg)'
        })
      ),
      state(
        'false',
        style({
          transform: 'rotate(0deg)'
        })
      ),
      transition('true <=> false', [animate('200ms ease-in')])
    ])
  ]
})
export class SideNavComponent {
  @Input() isSidebarCollapsed = false;
  @Input() navItems: NavigationItem[] = [];
  public userContext: UserContextDetails;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService
  ) {
    this.authService.userContextDetails$.subscribe((userContext: UserContextDetails) => {
      this.userContext = userContext;
    });
  }

  public loadComponent(action: NavigationAction): void {
    if (action.componentName == MicroServicesComponent) {
      const dialog: DialogRef = this.dialogService.open({
        content: action.componentName,
        width: 1200,
        maxHeight: '80%',
        minHeight: 400
      });
    } else if (action.componentName == EfileImportComponent) {
      const dialog: DialogRef = this.dialogService.open({
        content: action.componentName,
        width: 600,
        maxHeight: '90%',
        minHeight: 300
      });
    } else {
      const dialog: DialogRef = this.dialogService.open({
        content: action.componentName,
        cssClass: 'dialog-sizing-md'
      });
    }
  }

  userAccessAllowed(navItem) {
    return navItem.blockedUserTypes == undefined || navItem.blockedUserTypes.indexOf(this.userContext.userTypeId) == -1;
  }
}
